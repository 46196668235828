import React from 'react';
import axios, { AxiosError } from 'axios';
import { createGlobalState } from 'react-global-hooks';

type Alert = {
  type: 'success' | 'error' | 'info' | 'warning';
  content: string | JSX.Element;
};

export const messageState = createGlobalState([] as Alert[]);

export const addAlert = (alert: Alert) => {
  const newAlerts = [...messageState.get(), alert];
  messageState.set(newAlerts);
};

export const getAxiosErrors = (e: Error | AxiosError | unknown): string[] => {
  if (!axios.isAxiosError(e)) {
    return ['Something went wrong'];
  } //@ts-ignore
  if (e.response?.data?.errors) {
    //@ts-ignore
    return e.response.data.errors.map((x) => x.message as string);
    //@ts-ignore
  } else if (e.response?.data?.message) {
    //@ts-ignore
    const newError = e.response.data.message;
    return [newError];
  } else {
    return ['Something went wrong'];
  }
};

/**
 * Pass errors here to have them automatically displayed
 */
export const alertApiErrors = (e: Error | AxiosError | unknown) => {
  if (!axios.isAxiosError(e)) {
    addAlert({
      type: 'error',
      content: 'Something went Wrong',
    });
    console.error(e);
  } else {
    //@ts-ignore
    if (e.response?.data?.errors) {
      //@ts-ignore
      const newErrors = e.response.data.errors.map((x) => x.message as string);
      addAlert({
        type: 'error',
        //@ts-ignore
        content: (
          <>
            {newErrors.map((x: any, i: number) => (
              <React.Fragment key={i}>
                {i > 0 ? <br /> : null}
                {x}
              </React.Fragment>
            ))}
          </>
        ),
      });
      //@ts-ignore
    } else if (e.response?.data?.message) {
      //@ts-ignore
      const newError = e.response.data.message;
      addAlert({
        type: 'error',
        content: newError,
      });
    } else {
      console.error(e);
      addAlert({
        type: 'error',
        content: 'Something went Wrong',
      });
    }
  }
};
