import React, { useState, useEffect } from 'react';
import style from './Dashboard.module.scss';
import Heading from '@components/Heading';
import Notification from '@components/Notification';
import INotification from '@interfaces//Notification';
import listNotifications from '@api/notification/listNotifications';
import { Empty } from 'antd';
import rolesState from '@globalState/rolesState';
import AddNotificationsComponent from '@pages/Dashboard/AddNotificationsComponent';
import moment from 'moment';

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState(null as null | INotification[]);
  const roles = rolesState.useValue();

  const triggerUpdate = async () => {
    setIsLoading(true);
    let n = await listNotifications();
    // Sort by date
    n = n.sort((a, b) => {
      return moment(b.dateCreated).diff(moment(a.dateCreated));
    });
    setNotifications(n);
    setIsLoading(false);
  };
  useEffect(() => {
    triggerUpdate();
  }, []);

  return (
    <div>
      <div className={style.top}>
        <div className={style.title}>Dashboard</div>
        <div className={style.description}>Welcome to the Umami dashboard.</div>
      </div>
      <div className={style.content}>
        {roles?.isAdmin ? <AddNotificationsComponent triggerUpdate={triggerUpdate} /> : null}
        <Heading>Notifications</Heading>
        {isLoading ? (
          'Loading...'
        ) : notifications && notifications.length > 0 ? (
          notifications.map((n) => {
            return <Notification key={n.id} notification={n} triggerUpdate={triggerUpdate} />;
          })
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
