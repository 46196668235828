import React from 'react';
import style from './Notification.module.scss';
import INotification from '@interfaces//Notification';
import moment from 'moment';
import rolesState from '@globalState/rolesState';
import deleteNotification from '@api/notification/deleteNotification';

type Props = {
  notification: INotification;
  triggerUpdate: () => void | Promise<void>;
};
const Notification = ({ notification, triggerUpdate }: Props) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const roles = rolesState.useValue();
  return (
    <div className={style.notification}>
      <div className={style.title}>
        {notification.title}
        {roles?.isAdmin ? (
          <div
            className={style.delete}
            onClick={async () => {
              if (isDeleting) return;
              if (!window.confirm('Are you sure you want to delete this notification?')) return;
              await deleteNotification(notification.id);
              await triggerUpdate();
            }}
          >
            Delete
          </div>
        ) : null}
      </div>
      <div className={style.description}>{notification.content}</div>
      <div className={style.footer}>
        <div className={style.avatar}>
          <img src={`/images/umami-icon.svg`} alt="" />
        </div>
        <span className={style.name}>Umami Wine</span>
        <span className={style.date}>{moment(notification.dateCreated).format('LLL')}</span>
      </div>
    </div>
  );
};

export default Notification;
