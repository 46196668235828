import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

const listCompanies = async (): Promise<any> => {
  // const res = await axiosSecure.post(`${getContextRoot()}/company?max=25&offset=0`);
  const res = await axiosSecure.get(`${getContextRoot()}/company`);
  return res.data;
};

export default listCompanies;
