import React from 'react';
import style from './Account.module.scss';
import Heading from '@components/Heading';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import currentUserState, { updateCurrentUser } from '@globalState/currentUserState';
import modifyUser, { Opt } from '@api/context/user/modifyUser';
import { addAlert, alertApiErrors, messageState } from '@utils/axiosErrors';

type AccountForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const blankAccountForm: AccountForm = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

type Errors = {
  [key: string]: string;
};

export default () => {
  const [form, setForm] = React.useState(blankAccountForm);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<Errors>({});
  const currentUser = currentUserState.useValue();

  React.useEffect(() => {
    if (currentUser) {
      setForm({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        password: '',
        confirmPassword: '',
      });
    } else {
      setForm(blankAccountForm);
    }
  }, [currentUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (Object.keys(errors).length > 0) {
      checkErrors();
    }
  };

  const checkErrors = () => {
    const errors: Errors = {};
    // only check password if entered
    if (form.password.length > 0) {
      if (form.password !== form.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
        errors.password = 'Passwords do not match';
      }
      if (form.password.length < 8) {
        errors.password = 'Password must be at least 8 characters';
      }
    }
    // must be valid email
    if (!form.email.match(/.+@.+\..+/)) {
      errors.email = 'Email is invalid';
    }
    if (form.email.length < 1) {
      errors.email = 'Email is required';
    }
    if (form.lastName.length < 1) {
      errors.lastName = 'Last name is required';
    }
    if (form.firstName.length < 1) {
      errors.firstName = 'First name is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) return;
    // only check password if entered
    if (checkErrors()) {
      let data: Opt = {
        username: currentUser.username,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
      };
      if (form.password.length > 0) {
        data.password = form.password;
      }
      try {
        setIsLoading(true);
        await modifyUser(currentUser.id, data);
        updateCurrentUser();
        addAlert({ type: 'success', content: 'Account updated successfully' });
      } catch (e) {
        alertApiErrors(e);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={style.container}>
      <Heading>Account</Heading>
      <form onSubmit={handleSubmit}>
        <input type="hidden" value="email" />

        <div className={errors.firstName ? classNames(style.label, style.error) : style.label}>
          First Name
        </div>
        <Input
          name={'firstName'}
          status={errors.firstName ? 'error' : undefined}
          placeholder="John"
          value={form.firstName}
          onChange={handleChange}
          autoComplete={'off'}
        />
        {errors.firstName && <div className={style.errorText}>{errors.firstName}</div>}

        <div className={errors.lastName ? classNames(style.label, style.error) : style.label}>
          Last Name
        </div>
        <Input
          name={'lastName'}
          status={errors.lastName ? 'error' : undefined}
          placeholder="Doe"
          value={form.lastName}
          onChange={handleChange}
          autoComplete={'off'}
        />
        {errors.lastName && <div className={style.errorText}>{errors.lastName}</div>}

        <div className={errors.email ? classNames(style.label, style.error) : style.label}>
          Email
        </div>
        <Input
          name={'email'}
          status={errors.email ? 'error' : undefined}
          placeholder="john@doe.com"
          value={form.email}
          onChange={handleChange}
          autoComplete={'off'}
        />
        {errors.email && <div className={style.errorText}>{errors.email}</div>}

        <div className={errors.password ? classNames(style.label, style.error) : style.label}>
          Password
        </div>
        <Input
          name={'password'}
          status={errors.password ? 'error' : undefined}
          placeholder="********"
          type="password"
          value={form.password}
          onChange={handleChange}
          autoComplete={'new-password'}
        />
        {errors.password && <div className={style.errorText}>{errors.password}</div>}

        <div
          className={errors.confirmPassword ? classNames(style.label, style.error) : style.label}
        >
          Confirm Password
        </div>
        <Input
          name={'confirmPassword'}
          status={errors.confirmPassword ? 'error' : undefined}
          placeholder="********"
          type="password"
          value={form.confirmPassword}
          onChange={handleChange}
          autoComplete={'off'}
        />
        {errors.confirmPassword && <div className={style.errorText}>{errors.confirmPassword}</div>}
        <div style={{ marginTop: 20 }} />
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </form>
    </div>
  );
};
