import React from 'react';
import style from './YoutubeEmbed.module.scss';

type Props = {
  url: string | null;
};

const YoutubeEmbed = ({ url }: Props) => {
  // get the embed id from youtube url
  const embedId = React.useMemo(() => {
    if (!url) return null;
    const regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#&?]*).*/;
    const match = url.match(regex);
    return match && match[1].length === 11 ? match[1] : null;
  }, [url]);

  if (!embedId) return null;

  return (
    <div className={style.videoResponsive}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubeEmbed;
