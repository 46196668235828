import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  name?: string;
  wines?: { id: number };
}

const modifyProducer = async (producerId: string | number, opt: Opt): Promise<any> => {
  const res = await axiosSecure.put(`${getContextRoot()}/producer/${producerId}`, opt);
  return res.data;
};

export default modifyProducer;
