import React, { useMemo } from 'react';
import Heading from '@components/Heading';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Input, Menu, Switch } from 'antd';
import style from './ManageUpsells.module.scss';
import classNames from 'classnames';
import { FaTrash } from 'react-icons/fa';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type MenuObject = {
  id: number;
  label: string;
  icon: string;
  count: number;
  children?: MenuObject[];
};

const menu: MenuObject[] = [
  {
    id: 1,
    label: 'Varietal',
    icon: '/images/icons/varietal.svg',
    count: 4,
    children: [
      {
        id: 2,
        label: 'Red',
        icon: '/images/icons/varietal.svg',
        count: 2,
      },
      {
        id: 3,
        label: 'White',
        icon: '/images/icons/varietal.svg',
        count: 2,
      },
    ],
  },
  {
    id: 4,
    label: 'Flavor Profile',
    icon: '/images/icons/flavor.svg',
    count: 4,
    children: [
      {
        id: 5,
        label: 'Fruity',
        icon: '/images/icons/flavor.svg',
        count: 2,
      },
      {
        id: 6,
        label: 'Spicy',
        icon: '/images/icons/flavor.svg',
        count: 2,
      },
    ],
  },
  {
    id: 7,
    label: 'Food Pairing',
    icon: '/images/icons/food-pairings.svg',
    count: 4,
    children: [
      {
        id: 8,
        label: 'Salad',
        icon: '/images/icons/salad.svg',
        count: 6,
      },
      {
        id: 9,
        label: 'Light Appetizers',
        icon: '/images/icons/appetizers.svg',
        count: 2,
      },
      {
        id: 10,
        label: 'Cheese / Eggs',
        icon: '/images/icons/cheese-eggs.svg',
        count: 9,
      },
      {
        id: 11,
        label: 'Soup / Rich Appetizers',
        icon: '/images/icons/soup.svg',
        count: 0,
      },
      {
        id: 12,
        label: 'Pasta',
        icon: '/images/icons/pasta.svg',
        count: 3,
      },
      {
        id: 13,
        label: 'Spicy Latin / Southwest',
        icon: '/images/icons/spicy.svg',
        count: 12,
      },
      {
        id: 14,
        label: 'Fish',
        icon: '/images/icons/fish.svg',
        count: 1,
      },
      {
        id: 15,
        label: 'Rich Fish',
        icon: '/images/icons/lobster.svg',
        count: 8,
      },
      {
        id: 16,
        label: 'White Meat',
        icon: '/images/icons/chicken.svg',
        count: 4,
      },
      {
        id: 17,
        label: 'Red Meat',
        icon: '/images/icons/cow.svg',
        count: 5,
      },
      {
        id: 18,
        label: 'Wild Game',
        icon: '/images/icons/duck.svg',
        count: 7,
      },
      {
        id: 19,
        label: 'Sweets',
        icon: '/images/icons/sweets.svg',
        count: 4,
      },
    ],
  },
];

type Item = {
  id: number | null;
  title: string;
  img: string | null;
  active: boolean;
  url: string;
  groups: number[];
};

const testItems: Item[] = [
  {
    id: 1,
    title: 'H‑E‑B Prime 1 Beef Boneless Ribeye Steak, USDA Prime',
    img: 'https://images.heb.com/is/image/HEBGrocery/prd-small/h-e-b-prime-1-beef-boneless-ribeye-steak-usda-prime-000372054.jpg',
    active: true,
    url: 'https://www.heb.com/product-detail/h-e-b-prime-1-beef-boneless-ribeye-steak-usda-prime/372054',
    groups: [2],
  },
  {
    id: 2,
    title: 'H‑E‑B Prime 1 Beef Beef Tomahawk Steak Bone‑In Thick',
    img: 'https://images.heb.com/is/image/HEBGrocery/prd-small/h-e-b-prime-1-beef-beef-tomahawk-steak-bone-in-thick-002119295.jpg',
    active: true,
    url: 'https://www.heb.com/product-detail/h-e-b-prime-1-beef-beef-tomahawk-steak-bone-in-thick/2119295',
    groups: [2],
  },
  {
    id: 3,
    title: 'H‑E‑B Prime 1 Beef Boneless Ribeye Steak, USDA Prime',
    img: 'https://images.heb.com/is/image/HEBGrocery/prd-small/h-e-b-prime-1-beef-boneless-ribeye-steak-usda-prime-000372054.jpg',
    active: true,
    url: 'https://www.heb.com/product-detail/h-e-b-prime-1-beef-boneless-ribeye-steak-usda-prime/372054',
    groups: [17],
  },
  {
    id: 4,
    title: 'H‑E‑B Prime 1 Beef Beef Tomahawk Steak Bone‑In Thick',
    img: 'https://images.heb.com/is/image/HEBGrocery/prd-small/h-e-b-prime-1-beef-beef-tomahawk-steak-bone-in-thick-002119295.jpg',
    active: true,
    url: 'https://www.heb.com/product-detail/h-e-b-prime-1-beef-beef-tomahawk-steak-bone-in-thick/2119295',
    groups: [17],
  },
];

const blankItem: Item = {
  id: null,
  title: '',
  img: null,
  active: true,
  url: '',
  groups: [],
};

const placeholder = '/images/placeholder.jpg';

export default () => {
  const [selectedKeys, setSelectedKeys] = React.useState(['2'] as string[]);
  const [items, setItems] = React.useState(testItems as Item[]);

  const menuItems = useMemo(() => {
    return menu.map((item) => {
      return getItem(
        item.label,
        item.id,
        <img alt={'icon'} src={item.icon} />,
        item.children?.map((child) => {
          return getItem(child.label, child.id, <img alt={'icon'} src={child.icon} />);
        })
      );
    });
  }, [menu]);

  const selectedMenuObject: MenuObject | null = useMemo(() => {
    let obj: MenuObject | null = null;
    menu.forEach((item) => {
      if (item.id === Number(selectedKeys[0])) {
        obj = item;
      }
      item.children?.forEach((child) => {
        if (child.id === Number(selectedKeys[0])) {
          obj = child;
        }
      });
    });
    return obj;
  }, [selectedKeys, menuItems]);

  const selectedItems = useMemo(() => {
    if (!selectedMenuObject) return null;
    return items.filter((item) => {
      return item.groups.includes((selectedMenuObject as MenuObject).id);
    });
  }, [selectedMenuObject, items]);

  const hasNewItemInGroup = useMemo(() => {
    return items.some((item) => {
      // @ts-ignore
      return item.id === null && item.groups.includes((selectedMenuObject as MenuObject)?.id);
    });
  }, [items, selectedMenuObject]);

  const updateItem = (item: Item, createItem = false) => {
    const newItems = items.map((i) => {
      if (item.id === null || createItem) {
        // @ts-ignore
        if (i.id === null && i.groups.includes((selectedMenuObject as MenuObject)?.id)) {
          // todo create item
          return item;
        }
      } else if (i.id === item.id) {
        return item;
      }
      return i;
    });
    setItems(newItems);
  };

  const deleteItem = (item: Item) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      const newItems = items.filter((i) => {
        if (item.id === null) {
          // @ts-ignore
          return i.id !== null || !i.groups.includes((selectedMenuObject as MenuObject)?.id);
        }
        return i.id !== item.id;
      });
      setItems(newItems);
    }
  };

  const saveItem = (item: Item) => {
    // todo save item
    updateItem({ ...item, id: Math.floor(Math.random() * 100000) }, true);
  };

  console.log('selectedItems', selectedItems);

  return (
    <div className={classNames(style.container, 'side-menu')}>
      <Heading>Manage Upsells</Heading>
      <div className={style.left}>
        <Menu
          onClick={(e) => {
            setSelectedKeys([e.key.toString()]);
          }}
          selectedKeys={selectedKeys}
          style={{ width: 256 }}
          defaultSelectedKeys={[]}
          defaultOpenKeys={menu.map((item) => item.id.toString())}
          mode="inline"
          items={menuItems}
        />
      </div>
      <div className={style.right}>
        <div className={style.titleTop}>
          <div className={style.titleTopLeft}>
            {/*//@ts-ignore*/}
            <Heading>{selectedMenuObject?.label ? selectedMenuObject.label : '(None)'}</Heading>
          </div>
          <div className={style.titleTopCenter} />
          <div className={style.titleTopRight}>
            <Button
              type={'primary'}
              disabled={!selectedMenuObject}
              onClick={() => {
                if (selectedMenuObject && !hasNewItemInGroup) {
                  // @ts-ignore
                  setItems([...items, { ...blankItem, groups: [selectedMenuObject.id] }]);
                }
              }}
            >
              New
            </Button>
          </div>
        </div>
        <div>
          {selectedItems?.map((item, index) => {
            return (
              <div key={index} className={style.item}>
                <div className={style.imgBox}>
                  <img alt={'item'} src={item.img ?? placeholder} />
                </div>
                <div className={style.mainBox}>
                  URL <small>(the product page)</small>
                  <div className={style.inputBox}>
                    <Input
                      placeholder="https://product-page.example.com"
                      value={item.url}
                      onChange={(e) => {
                        updateItem({ ...item, url: e.target.value });
                      }}
                    />
                  </div>
                  <div className={style.title}>{item.title}</div>
                </div>
                <div className={style.toggleBox}>
                  <div className={style.toggleText}>ACTIVE</div>
                  <Switch
                    defaultChecked
                    checked={item.active}
                    onChange={(checked) => {
                      updateItem({ ...item, active: checked });
                    }}
                  />
                </div>
                <div className={style.buttonBox}>
                  {item.id === null ? (
                    <Button
                      type={'primary'}
                      onClick={() => {
                        saveItem(item);
                      }}
                    >
                      Save
                    </Button>
                  ) : null}
                  <Button
                    type="primary"
                    size="middle"
                    onClick={() => {
                      deleteItem(item);
                    }}
                    danger
                  >
                    <FaTrash />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.clearfix} />
    </div>
  );
};
