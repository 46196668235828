import { createGlobalState } from 'react-global-hooks';
import currentUserState from '@globalState/currentUserState';

export type AuthState = {
  username: string;
  roles: string[];
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
};

const authState = createGlobalState(null as null | AuthState);

// handle saving and recovering authState from localStorage
const currentAuthState = window.localStorage.getItem('authState');
if (currentAuthState) {
  authState.set(JSON.parse(currentAuthState));
}

authState.onChange((newState) => {
  if (newState) {
    window.localStorage.setItem('authState', JSON.stringify(newState));
  } else {
    currentUserState.set(null);
    window.localStorage.removeItem('authState');
  }
});

export default authState;
