import axiosSecure from '@api/axiosSecure';
import CurrentUser from '@interfaces/CurrentUser';
import apiRoot from '@api/apiRoot';

const getCurrentUser = async (): Promise<CurrentUser> => {
  const res = await axiosSecure.get(`${apiRoot}/user/userInfo`);
  return res.data;
};

export default getCurrentUser;
