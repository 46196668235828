import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';
import FoodPairing from '@interfaces/FoodPairing';

const listFoodPairings = async (): Promise<any[]> => {
  const res = await axiosSecure.get(`${getContextRoot()}/foodPairing`);
  return res.data;
};

export default listFoodPairings;
