import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

const listWines = async (): Promise<any> => {
  const res = await axiosSecure.get(`${getContextRoot()}/wine`);
  if ('wines' in res.data) {
    return res.data.wines;
  }
  return res.data;
};

export default listWines;
