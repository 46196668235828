import axiosSecure from "@api/axiosSecure";
import getContextRoot from "@api/getContextRoot";


const listFlavorById = async (flavorId: string | number): Promise<any> => {
  const res = await axiosSecure.get(`${getContextRoot()}/flavorProfile/${flavorId}`);
  // const res = await axiosSecure.get(`${getContextRoot()}/wine?max=25&offset=0`);
  return res.data;
};

export default listFlavorById;
