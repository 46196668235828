import currentUserState from "@globalState/currentUserState";
import FlavorProfile from "@interfaces/FlavorProfile";
import { Card, Dropdown, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { FaCaretDown, FaPencilAlt, FaTrash } from "react-icons/fa";
import { AddFlavorFoodPairingModalState, addFlavorFoodPairingModalState, openNewFoodPairingModal } from "./AddFlavorFoodPairing";
import deleteFlavor from "@api/context/wine/flavor/deleteFlavor";
import deleteFoodPairing from "@api/context/wine/foodPairing/deleteFoodPairing";


type Props = {
    flavorFoodDetails: FlavorProfile[];
    updateFlavorProfile: () => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
};

export default ({ flavorFoodDetails, updateFlavorProfile, isLoading, setIsLoading }: Props) => {

    const currentUser = currentUserState.useValue();

    const unusedFoodPairingColumns: ColumnsType<FlavorProfile> = [
        {
            title: 'Flavor Profile',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Food Pairings',
            dataIndex: 'foodPairings',
            key: 'foodPairings',

            render: (_, { foodPairings }) => {
                // Sort food pairings based on orderId
                const sortedFoodPairings = foodPairings?.sort((a, b) => (a.orderId || 0) - (b.orderId || 0));
        
                return (
                  <>
                    {sortedFoodPairings?.map((category, index) => {
                      return (
                        <Tag key={category?.name ?? "" + index}>
                          {category?.name?.toUpperCase()}
                        </Tag>
                      );
                    })}
                  </>
                );
              },
            },
        {
            title: 'Level',
            dataIndex: 'type',
            key: 'level',

            render: (text: any, record: FlavorProfile) => {
                let flavorType = "";

                if (record.type === 'GlobalFlavorProfile') {
                    flavorType = "Global FlavorProfile"
                } else if (record.type === 'CompanyFlavorProfile') {
                    flavorType = "Company FlavorProfile"
                } else if (record.type === 'StoreFlavorProfile') {
                    flavorType = "Store FlavorProfile"
                }
                return <p>{flavorType}</p>;
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '100px',
            fixed: 'right',
            render: (text: any, record: FlavorProfile) => {
                let showActions = false;

                // admins can edit and delete everything
                if (currentUser?.isAdmin) {
                    showActions = true;
                }

                if (currentUser?.isMaster && record.type === 'CompanyFlavorProfile' || record.type === 'StoreFlavorProfile') {
                    showActions = true;
                }

                if (currentUser?.isStore && record.type === 'StoreFlavorProfile') {
                    showActions = true;
                }

                if (!showActions) {
                    return null;
                }
                return (
                    <Dropdown
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: `${record.id}a`,
                                    label: (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                let data: AddFlavorFoodPairingModalState = {
                                                    id: record.id,
                                                    name: record.name ?? '',
                                                    type: record.type ?? '',
                                                    foodPairings: record.foodPairings ?? [],
                                                    // foodPairings: (record.foodPairings ?? []).map(obj => obj.name ?? ""),
                                                    onComplete: updateFlavorProfile,
                                                };

                                                addFlavorFoodPairingModalState.set(data);
                                            }}
                                        >
                                            <FaPencilAlt /> Edit
                                        </a>
                                    ),
                                },
                                {
                                    key: `${record.id}b`,
                                    label: (
                                        <a
                                            href="#"
                                            className={'text-danger'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log('delete', record);
                                                if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                                                    setIsLoading(true);
                                                    deleteFoodPairing(record.id).then(() => {
                                                        updateFlavorProfile();
                                                    })
                                                }
                                            }}
                                        >
                                            <FaTrash /> Delete
                                        </a>
                                    ),
                                },
                            ],
                        }}
                    >
                        <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
                            Action <FaCaretDown />
                        </a>
                    </Dropdown>
                );
            },
        },
    ];


    return (
        <>
            <Card
                title={'Food Pairing'}
                extra={
                    <>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                openNewFoodPairingModal(updateFlavorProfile);
                            }}
                        >
                            Create FoodPairing
                        </a>
                    </>
                }
            >
                <Table
                    dataSource={flavorFoodDetails}
                    columns={unusedFoodPairingColumns}
                    loading={isLoading}
                    scroll={{ x: 1300 }}
                    rowKey="id"
                />
            </Card>
        </>
    );
}