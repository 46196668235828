import React from 'react';
import style from './Loading.module.scss';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export default () => {
  return (
    <div className={style.loading}>
      <div className={style.center}>
        <div className={style.img}>
          <img src={'/images/umami-icon.png'} alt={'logo'} />
        </div>
        <div className={style.text}>Umami Wine</div>
        <div className={style.subtext}>
          <AiOutlineLoading3Quarters className={'spin'} />
        </div>
      </div>
    </div>
  );
};
