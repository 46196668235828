import deleteLocation from "@api/context/wine/location/deleteLocation";
import currentUserState from "@globalState/currentUserState";
import Location, { LocationType } from "@interfaces/Location";
import { Card, Dropdown } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { FaCaretDown, FaPencilAlt, FaTrash } from "react-icons/fa";
import { AddLocationModalState, addLocationModalState, openNewLocationModal } from "./AddLocationModal";

type Props = {
    locations: Location[];
    updateLocations: () => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
};

export default ({ locations, updateLocations, isLoading, setIsLoading }: Props) => {
    const currentUser = currentUserState.useValue();

    const unusedLocationsColumns: ColumnsType<Location> = [
        {
            title: 'Country / US State',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
        },
        {
            title: 'Sub Region',
            dataIndex: 'subRegion',
            key: 'subRegion',
        },
        {
            title: 'Additional Information',
            dataIndex: 'additionalInformation',
            key: 'additionalInformation',
        },
        {
            title: 'Level',
            dataIndex: 'type',
            key: 'level',

            render: (text: any, record: Location) => {
                let locationType = "";

                if (record.type === 'GlobalLocation') {
                    locationType = "Global Location"
                } else if (record.type === 'CompanyLocation') {
                    locationType = "Company Location"
                } else if (record.type === 'StoreLocation') {
                    locationType = "Store Location"
                }
                return <p>{locationType}</p>;
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '100px',
            fixed: 'right',
            render: (text: any, record: Location) => {
                let showActions = false;

                // admins can edit and delete everything
                if (currentUser?.isAdmin) {
                    showActions = true;
                }
                if (currentUser?.isMaster && record.type == 'CompanyLocation' || record.type == 'StoreLocation') {
                    showActions = true;
                }

                if (currentUser?.isStore && record.type === 'StoreLocation') {
                    showActions = true;
                }

                if (!showActions) {
                    return null;
                }
                return (
                    <Dropdown
                        placement={'bottomRight'}
                        menu={{
                            items: [
                                {
                                    key: `${record.id}a`,
                                    label: (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log("record :", record);
                                                
                                                let data: AddLocationModalState = {
                                                    id: record.id,
                                                    name: record.name ?? '',
                                                    region: record.region ?? '',
                                                    subRegion: record.subRegion ?? '',
                                                    additionalInformation: record.additionalInformation ?? '',
                                                    onComplete: updateLocations,
                                                    type: record.type,
                                                    wines: null,
                                                };

                                                addLocationModalState.set(data);
                                            }}
                                        >
                                            <FaPencilAlt /> Edit
                                        </a>
                                    ),
                                },
                                {
                                    key: `${record.id}b`,
                                    label: (
                                        <a
                                            href="#"
                                            className={'text-danger'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log('delete', record);
                                                if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                                                    setIsLoading(true);
                                                    deleteLocation(record.id).then(() => {
                                                        updateLocations();
                                                    });
                                                }
                                            }}
                                        >
                                            <FaTrash /> Delete
                                        </a>
                                    ),
                                },
                            ],
                        }}
                    >
                        <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
                            Action <FaCaretDown />
                        </a>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <Card
                title={'Location'}
                extra={
                    <>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                openNewLocationModal(updateLocations);
                            }}
                        >
                            Create Location
                        </a>
                    </>
                }
            >
                <Table
                    dataSource={locations}
                    columns={unusedLocationsColumns}
                    loading={isLoading}
                    scroll={{ x: 1300 }}
                    rowKey="id"
                />
            </Card>
        </>
    );

}   
