import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import Theme from './Theme';
import ApplicationLayout from './ApplicationLayout';
import Account from './pages/Account';
import Dashboard from './pages/Dashboard';
import ManageStores from './pages/ManageStores';
import ManageCompanies from './pages/ManageCompanies';
import ManageUsers from './pages/ManageUsers';
import ManageUpsells from './pages/ManageUpsells';
import ManageWines from './pages/ManageWines';
import WidgetDesigner from './pages/WidgetDesigner';
import isReadyState from '@globalState/isReadyState';
import currentUserState from '@globalState/currentUserState';
import Loading from '@pages/Loading';
import Login from '@pages/Login';
import Upload from '@pages/Upload';
import ManageProducers from '@pages/ManageProducers/ManageProducers';
import ManageLocation from '@pages/ManageLocation/ManageLocation';
import ManageVarietal from '@pages/ManageVarietals/ManageVarietal';
import ManagFoodPairing from '@pages/ManageFoodPairing/ManageFoodPairing';
import ManageFoodPairing from '@pages/ManageFoodPairing/ManageFoodPairing';

type Props = {
  to: string;
};

const Redirect = ({ to }: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return <></>;
};

export default function RouterApp() {
  const [isReady, setIsReady] = isReadyState.use();
  const [currentUser, setCurrentUser] = currentUserState.use();
  if (!isReady) {
    return <Loading />;
  }
  if (!currentUser) {
    return <Login />;
  }
  return (
    <Router>
      <Theme>
        <ApplicationLayout>
          <Routes>
            <Route path="/" element={<Redirect to={'/dashboard'} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/manage-stores" element={<ManageStores />} />
            <Route path="/manage-companies" element={<ManageCompanies />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/manage-wines" element={<ManageWines />} />
            <Route path="/manage-producers" element={<ManageProducers />} />
            <Route path="/manage-varietals" element={<ManageVarietal />} />
            <Route path="/manage-locations" element={<ManageLocation />} />
            <Route path="/manage-foodPairing" element={<ManageFoodPairing/>} />
            <Route path="/manage-upsells" element={<ManageUpsells />} />
            <Route path="/widget-designer" element={<WidgetDesigner />} />
            <Route path="/account" element={<Account />} />
            <Route path="*" element={<Redirect to={'/dashboard'} />} />
          </Routes>
        </ApplicationLayout>
      </Theme>
    </Router>
  );
}
