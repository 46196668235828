import React, { useState, useRef } from 'react';
import { Input, Modal } from 'antd';
import { createGlobalState } from 'react-global-hooks';
import useFromNull from '@utils/useFromNull';
import currentUserState from '@globalState/currentUserState';
import { alertApiErrors } from '@utils/axiosErrors';
import addCompany, { Opt } from '@api/context/company/addCompany';
import modifyCompany from '@api/context/company/modifyCompany';

export type AddCompanyModalState = {
  id?: number;
  name: string;
  onComplete?: () => void | Promise<void>;
};

const emptyAddUserModal: AddCompanyModalState = {
  name: '',
};

export const addCompanyModalState = createGlobalState(null as AddCompanyModalState | null);

export const openNewCompanyModal = (onComplete: () => void | Promise<void> = () => {}) => {
  const currentUser = currentUserState.get();
  let data: any = { ...emptyAddUserModal, onComplete };
  if (currentUser?.isStore || currentUser?.isMaster) {
    data.company = currentUser?.company?.id || -1;
  }
  addCompanyModalState.set(data);
};

const AddCompanyModal = () => {
  const [addUserModal, setAddUserModal] = addCompanyModalState.use();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const firstInputRef = useRef<any>(null);

  const isNew = !addUserModal?.id;

  useFromNull(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 300);
  }, [addUserModal]);

  const handleCancel = () => {
    setAddUserModal(null);
  };
  const handleSubmit = async () => {
    setConfirmLoading(true);
    try {
      let opts: Opt = {
        name: addUserModal?.name || '',
      };
      if (isNew) {
        await addCompany(opts);
      } else {
        // @ts-ignore
        await modifyCompany(addUserModal?.id || -1, opts);
      }
      if (addUserModal?.onComplete) {
        await addUserModal.onComplete();
      }
      // todo submit and refresh
      setConfirmLoading(false);
      setAddUserModal(null);
    } catch (e) {
      console.error(e);
      alertApiErrors(e);
      setConfirmLoading(false);
    }
  };
  const handleChange = (e: any) => {
    if (!addUserModal) return;
    setAddUserModal({
      ...addUserModal,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      title={`${isNew ? 'Add' : 'Edit'} User`}
      open={!!addUserModal}
      confirmLoading={confirmLoading}
      onOk={handleSubmit}
      okText={isNew ? 'Add' : 'Save'}
      onCancel={handleCancel}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <div style={{ marginTop: 20 }}>Name</div>
        <Input
          name={'name'}
          placeholder="Umami Wine"
          value={addUserModal?.name ?? ''}
          ref={firstInputRef}
          onChange={handleChange}
        />
      </form>
    </Modal>
  );
};

export default AddCompanyModal;
