import React from 'react';
import Heading from '@components/Heading';
import { Button, Card, Upload } from 'antd';
import authState from '@globalState/authState';
import { addAlert } from '@utils/axiosErrors';
import { FaCloudUploadAlt } from 'react-icons/fa';
import currentUserState from '@globalState/currentUserState';
import download from '@api/general/download';

type UploadType = {
  title: string;
  action: string;
  download: string;
};
const uploadArr = [
  {
    title: 'Producers',
    action: '/api/admin/upload/producers',
    download: '/api/admin/download/producers',
  },
  {
    title: 'Varietals',
    action: '/api/admin/upload/varietals',
    download: '/api/admin/download/varietals',
  },
  {
    title: 'Locations',
    action: '/api/admin/upload/regions',
    download: '/api/admin/download/regions',
  },
  {
    title: 'Flavor Profiles',
    action: '/api/admin/upload/flavorProfiles',
    download: '/api/admin/download/flavorProfiles',
  },
  {
    title: 'Master Wine List',
    action: '/api/admin/upload/wines',
    download: '/api/admin/download/wines',
  },
];

const UploadPage = () => {
  const auth = authState.useValue();
  const currentUser = currentUserState.useValue();
  const [isLoadingDownloadIdArr, setIsLoadingDownloadIdArr] = React.useState([] as number[]);

  if (!currentUser?.isAdmin) {
    return <div>Not authorized</div>;
  }

  return (
    <div style={{ padding: 20 }}>
      <Heading>Upload</Heading>

      {uploadArr.map((upload: UploadType, index) => {
        console.log('upload', upload);
        return (
          <div key={index}>
            {index !== 0 && <div style={{ height: 20 }} />}
            <Card title={upload.title}>
              <Button
                type={'primary'}
                loading={isLoadingDownloadIdArr.includes(index)}
                onClick={() => {
                  // get last part of path
                  const endpointName = upload.download.split('/').pop();
                  const filename = endpointName + '.csv';
                  setIsLoadingDownloadIdArr([...isLoadingDownloadIdArr, index]);
                  download(upload.download, filename).then(() => {
                    console.log('done');
                    setIsLoadingDownloadIdArr(isLoadingDownloadIdArr.filter((id) => id !== index));
                  });
                }}
              >
                Download
              </Button>
              <div style={{ height: 20 }} />
              <Upload
                name={'file'}
                action={upload.action}
                headers={{
                  Authorization: 'Bearer ' + auth?.access_token || '',
                }}
                onChange={(info) => {
                  console.log('info', info);
                  if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (info.file.status === 'done') {
                    addAlert({
                      type: 'success',
                      content: 'File uploaded successfully',
                    });
                  } else if (info.file.status === 'error') {
                    addAlert({
                      type: 'error',
                      content: 'Failed to upload file',
                    });
                  }
                }}
              >
                <Button icon={<FaCloudUploadAlt />}>Click to Upload</Button>
              </Upload>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default UploadPage;
