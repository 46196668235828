import React, { useEffect } from 'react';
import RouterApp from './RouterApp';
import currentUserState from '@globalState/currentUserState';
import getCurrentUser from '@api/user/getCurrentUser';
import isReadyState from '@globalState/isReadyState';
import useFromNull from '@utils/useFromNull';
import authState from '@globalState/authState';
import { devLog } from '@utils/devLog';
import StateManager from '@components/StateManager';
import MessageManager from '@components/MessageManager';

export default function App() {
  const auth = authState.useValue();
  const updateUser = () => {
    isReadyState.set(false);
    getCurrentUser()
      .then((user) => {
        devLog('user', user);
        if (user) {
          currentUserState.set(user);
        } else {
          currentUserState.set(null);
        }
        isReadyState.set(true);
      })
      .catch((err) => {
        console.error(err);
        currentUserState.set(null);
        isReadyState.set(true);
      });
  };
  useFromNull(() => {
    updateUser();
  }, [auth]);

  useEffect(() => {
    if (auth) {
      updateUser();
    }
  }, []);

  return (
    <>
      <StateManager />
      <MessageManager />

      <RouterApp />
    </>
  );
}
