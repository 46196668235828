import axios from 'axios';
import apiRoot from '@api/apiRoot';
import Wine from '@interfaces/Wine';

type Data = {
  upcCode: string;
  company?: number;
  store?: number;
};

export const getWidgetWine = async (data: Data) => {
  const res = await axios.post(`${apiRoot}/widget/wine`, data);
  return res.data as Wine;
};

export default getWidgetWine;
