import addLocation from "@api/context/wine/location/addLocation";
import listLocations from "@api/context/wine/location/listLocations";
import modifyLocation, { Opt } from "@api/context/wine/location/modifyLocation";
import NameAndId from "@interfaces/NameAndId";
import { alertApiErrors } from "@utils/axiosErrors";
import useFromNull from "@utils/useFromNull";
import { Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useMemo, useRef, useState } from "react";
import { createGlobalState } from "react-global-hooks";


export type AddLocationModalState = {
    id: number;
    name: string | null;
    region: string | null;
    subRegion?: string | null;
    country?: string | null;
    usState?: string | null;
    additionalInformation?: string | null;
    type: string;
    wines?: { id: number } | null;
    onComplete?: () => void | Promise<void>;
};

const emptyAddUserModal: AddLocationModalState = {
    id: 0,
    name: null,
    region: null,
    type: ""
};

export const addLocationModalState = createGlobalState(null as AddLocationModalState | null);

export const openNewLocationModal = (onComplete: () => void | Promise<void> = () => { }) => {
    addLocationModalState.set({ ...emptyAddUserModal, onComplete: onComplete });
};

const TextInput = React.forwardRef(
    (
        {
            name,
            label,
            handleChange,
            type = 'input',
        }: {
            name: string;
            label: string;
            handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
            type?: 'input' | 'textarea';
        },
        ref
    ) => {
        const addLocationModal = addLocationModalState.useValue();
        return (
            <div style={{ marginTop: 20 }}>
                {label}
                {type === 'textarea' ? (
                    <TextArea
                        name={name}
                        placeholder={label}
                        //@ts-ignore
                        value={addLocationModal?.[name] ?? ''}
                        onChange={handleChange}
                        //@ts-ignore
                        ref={ref}
                    />
                ) : (
                    <Input
                        name={name}
                        placeholder={label}
                        //@ts-ignore
                        value={addLocationModal?.[name] ?? ''}
                        onChange={handleChange}
                        //@ts-ignore
                        ref={ref}
                    />
                )}
            </div>
        );
    }
);

const AddLocationModal = () => {
    const [addLocationModal, setAddLocationModal] = addLocationModalState.use();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [locations, setLocations] = useState([] as NameAndId[]);
    const [region, setRegion] = useState<any[]>([]); // Fix 1
    const [subRegion, setSubRegion] = useState<any[]>([]);
    const isNew = !addLocationModal?.id;
    const firstInputRef = useRef<any>(null);

    useFromNull(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus();
            }
        }, 300);
        setIsLoading(true);
        listLocations().then((locations) => {
            setLocations(locations)
        }).catch((error) => {
            alertApiErrors(error);
            setIsLoading(false);
        })
    }, [addLocationModal]);

    const handleCancel = () => {
        setAddLocationModal(null);
    };

    const handleSubmit = async () => {
        if (!addLocationModal) return;
        setConfirmLoading(true);
        try {
            let opts: Opt = {
                name: addLocationModal.name ?? '',
                region: addLocationModal.region ?? '',
                subRegion: addLocationModal.subRegion ?? '',
                additionalInformation: addLocationModal.additionalInformation ?? '',
                wines:
                    addLocationModal.wines ? { id: addLocationModal.wines.id } : undefined,
            };
            if (isNew) {
                // todo add company for admins
                await addLocation(opts);
            } else {
                // todo add company for admins
                await modifyLocation(addLocationModal.id as number, opts);
            }
            if (addLocationModal?.onComplete) {
                await addLocationModal.onComplete();
            }
            setConfirmLoading(false);
            setAddLocationModal(null);
        } catch (e) {
            setConfirmLoading(false);
            alertApiErrors(e);
        }
    };

    const handleChange = (e: any) => {
        if (!addLocationModal) return;
        setAddLocationModal({
            ...addLocationModal,
            [e.target.name]: e.target.value,
        });
    };

    const regionBasedOnCounty = (value: any, options: any) => {

        const regions: any = [];
        options?.forEach((option: any) => {
            if (option.region && option.region !== '' && !regions.includes(option.region)) {
                if (option.name === value) {
                    regions.push(option.region);
                }
            }
        });
        setRegion([...regions]);
    };

    const stateBasedOnRegion = (value: any, options: any) => {
        const subRegions: any = [];
        options?.forEach((option: any) => {
            if (option.subRegion && option.subRegion !== '' && !subRegions.includes(option.subRegion)) {
                if (option.region === value) {
                    subRegions.push(option.subRegion);
                }
            }
        });

        setSubRegion([...subRegions]);
    };

    const SelectStateLocation = ({
        name,
        label,
        options,
        handleChange,
        isMulti = false,
    }: {
        name: string;
        label: string;
        options: any[];
        handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        isMulti?: boolean;
    }) => {

        const addLocationModal = addLocationModalState.useValue();
        const groupOptions = useMemo(() => {
            return region.map((r) => ({ label: r, value: r }));
        }, [region]);
        return (
            <div style={{ marginTop: 20 }}>
                {label}
                <Select
                    mode="tags"
                    allowClear={isMulti ? true : undefined}
                    placeholder={label}
                    //@ts-ignore
                    value={addLocationModal?.['region'] ?? '-- Select One -- '}
                    style={{ width: '100%' }}
                    showSearch={true}
                    onChange={(value, filter) => {

                        if (Array.isArray(filter) && (filter.length === 0 || filter.length === 1)) {
                            const syntheticEvent = {
                                target: {
                                    name,
                                    value,
                                },

                            }
                            handleChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                            stateBasedOnRegion(value, options)

                        } else {
                            const syntheticEvent = {
                                target: {
                                    name,
                                    value: value[1],
                                },

                            }
                            handleChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                            stateBasedOnRegion(value[1], options)
                        }

                    }}
                    options={groupOptions}
                />
            </div>
        );
    };

    const SelectCountyLocation = ({
        name,
        label,
        options,
        handleChange,
        isMulti = false,
    }: {
        name: string;
        label: string;
        options: any[];
        handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        isMulti?: boolean;
    }) => {
        const addLocationModal = addLocationModalState.useValue();
        const groupOptions = useMemo(() => {
            const groupNames: string[] = [];
            options?.forEach((option) => {
                if (option.name && option.name !== '' && !groupNames.includes(option.name)) {
                    groupNames.push(option.name);
                }
            });

            // sort group names alphabetically
            groupNames.sort((a, b) => {
                if (a < b) return -1;
                else if (a > b) return 1;
                else return 0;
            });
            let groups: any = [];
            // add default
            // groups.push({ label: ' -- Select One -- ', value: -1 });
            groupNames.forEach((groupName) => {
                groups.push({
                    label: groupName,
                    value: groupName,
                });
            });
            return groups;
        }, [options]);
        return (
            <div style={{ marginTop: 20 }}>
                {label}
                <Select
                    mode="tags"
                    allowClear={isMulti ? true : undefined}
                    placeholder={label}
                    //@ts-ignore
                    value={addLocationModal?.['name'] ?? '-- Select One  '}
                    style={{ width: '100%' }}
                    showSearch={true}
                    onChange={(value, filterData) => {

                        if (filterData?.length === 0 || filterData?.length === 1) {
                            const syntheticEvent = {
                                target: {
                                    name,
                                    value,
                                },

                            }
                            handleChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                            regionBasedOnCounty(value, options)

                        } else {
                            const syntheticEvent = {
                                target: {
                                    name,
                                    value: value[1],
                                },

                            }
                            handleChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                            regionBasedOnCounty(value[1], options)

                        }
                        //@ts-ignore
                        // handleChange({ target: { name, value } });
                        // regionBasedOnCounty(value, options)
                    }}
                    options={groupOptions}
                />
            </div>
        );
    };

    const SelectSubRegionLocation = ({
        name,
        label,
        options,
        handleChange,
        isMulti = false,
    }: {
        name: string;
        label: string;
        options: any[];
        handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        isMulti?: boolean;
    }) => {

        const addLocationModal = addLocationModalState.useValue();
        const groupOptions = useMemo(() => {
            return subRegion.map((r) => ({ label: r, value: r }));
        }, [subRegion]);
        return (
            <div style={{ marginTop: 20 }}>
                {label}
                <Select
                      mode="tags"
                    allowClear={isMulti ? true : undefined}
                    placeholder={label}
                    //@ts-ignore
                    value={addLocationModal?.['subRegion'] ?? '-- Select One -- '}
                    style={{ width: '100%' }}
                    showSearch={true}


                    onChange={(value, filterDataSubRegion) => {

                        if (Array.isArray(filterDataSubRegion) && (filterDataSubRegion.length === 0 || filterDataSubRegion.length === 1)) {
                            const syntheticEvent = {
                                target: {
                                    name,
                                    value,
                                },

                            }
                            handleChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                            stateBasedOnRegion(value, options)
                        } else {
                            const syntheticEvent = {
                                target: {
                                    name,
                                    value: value[1],
                                },

                            }
                            handleChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
                            stateBasedOnRegion(value[1], options)
                        }


                        //@ts-ignore
                        // handleChange({ target: { name, value } });
                        // stateBasedOnRegion(value, options);
                    }}
                    options={groupOptions}
                />
            </div>
        );
    };


    return (
        <Modal
            title={`${isNew ? 'Add' : 'Edit'} Location `}
            open={!!addLocationModal}
            confirmLoading={confirmLoading}
            onOk={handleSubmit}
            okText={'Save'}
            onCancel={handleCancel}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}
            >
                <div style={{ opacity: isLoading ? 0.5 : 1 }}>
                    <SelectCountyLocation
                        name={'name'}
                        label={'Name'}
                        options={locations}
                        handleChange={handleChange}
                    />
                    <SelectStateLocation
                        name={'region'}
                        label={'Region'}
                        options={locations}
                        handleChange={handleChange}
                    />
                    <SelectSubRegionLocation
                        name={'subRegion'}
                        label={'Sub Region'}
                        options={locations}
                        handleChange={handleChange}
                    />
                    <TextInput
                        name={'additionalInformation'} label={'Additional Information'} handleChange={handleChange} />
                </div>
            </form>
        </Modal>
    );
}

export default AddLocationModal;
