import axiosSecure from '@api/axiosSecure';

// downloads the file with secure jwt token headders
const download = async (url: string, fileName: string | null = null): Promise<any> => {
  const response = await axiosSecure({
    url: url,
    method: 'GET',
    responseType: 'blob',
  })
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);
  // get filename from blob
  // @ts-ignore
  const defaultFilename = fileName || response.headers['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '');

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', defaultFilename); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export default download;