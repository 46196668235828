import addProducer from "@api/context/wine/producer/addProducer";
import modifyProducer, { Opt } from "@api/context/wine/producer/modifyProducer";
import { alertApiErrors } from "@utils/axiosErrors";
import useFromNull from "@utils/useFromNull";
import { Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useRef, useState } from "react";
import { createGlobalState } from "react-global-hooks";

export type AddProducersModalState = {
  id?: number;
  name: string;
  type: string;
  wine: {
    id: number;
    name: string | null;
  } | null;
  onComplete?: () => void | Promise<void>;
};

const emptyAddUserModal: AddProducersModalState = {
  name: '',
  type: "",
  wine: null
};

export const addProducerModalState = createGlobalState(null as AddProducersModalState | null);

export const openNewProducerModal = (onComplete: () => void | Promise<void> = () => { }) => {
  addProducerModalState.set({ ...emptyAddUserModal, onComplete: onComplete });
};

const TextInput = React.forwardRef(
  (
    {
      name,
      label,
      handleChange,
      type = 'input',
    }: {
      name: string;
      label: string;
      handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
      type?: 'input' | 'textarea';
    },
    ref
  ) => {
    const addProducerModal = addProducerModalState.useValue();
    return (
      <div style={{ marginTop: 20 }}>
        {label}
        {type === 'textarea' ? (
          <TextArea
            name={name}
            placeholder={label}
            //@ts-ignore
            value={addProducerModal?.[name] ?? ''}
            onChange={handleChange}
            //@ts-ignore
            ref={ref}
          />
        ) : (
          <Input
            name={name}
            placeholder={label}
            //@ts-ignore
            value={addProducerModal?.[name] ?? ''}
            onChange={handleChange}
            //@ts-ignore
            ref={ref}
          />
        )}
      </div>
    );
  }
);

const AddProducerModal = () => {
  const [addProducerModal, setAddProducerModal] = addProducerModalState.use();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isNew = !addProducerModal?.id;

  const firstInputRef = useRef<any>(null);

  useFromNull(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 300);
    setIsLoading(true);
  }, [addProducerModal]);

  const handleCancel = () => {
    setAddProducerModal(null);
  };

  const handleSubmit = async () => {
    if (!addProducerModal) return;
    setConfirmLoading(true);
    try {
      let opts: Opt = {
        name: addProducerModal.name ?? '',
        wines:
          addProducerModal.wine ? { id: addProducerModal.wine.id } : undefined,
      };
      if (isNew) {
        // todo add company for admins
        await addProducer(opts);
      } else {
        // todo add company for admins
        await modifyProducer(addProducerModal.id as number, opts);
      }
      if (addProducerModal?.onComplete) {
        await addProducerModal.onComplete();
      }
      setConfirmLoading(false);
      setAddProducerModal(null);
    } catch (e) {
      setConfirmLoading(false);
      alertApiErrors(e);
    }
  };

  const handleChange = (e: any) => {
    if (!addProducerModal) return;
    setAddProducerModal({
      ...addProducerModal,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      title={`${isNew ? 'Add' : 'Edit'} Producer `}
      open={!!addProducerModal}
      confirmLoading={confirmLoading}
      onOk={handleSubmit}
      okText={'Save'}
      onCancel={handleCancel}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <div style={{ opacity: isLoading ? 0.5 : 1 }}>
          <TextInput name={'name'} label={'Producer Name'} handleChange={handleChange} />
        </div>
      </form>
    </Modal>
  );

};

export default AddProducerModal;

