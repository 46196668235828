import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import AddCompanyModal, {
  AddCompanyModalState,
  addCompanyModalState,
  openNewCompanyModal,
} from './AddCompanyModal';
import { FaCaretDown, FaPencilAlt, FaTrash } from 'react-icons/fa';
import listCompanies from '@api/context/company/listCompanies';
import Company from '@interfaces/Company';
import deleteCompany from '@api/context/company/deleteCompany';
import Heading from '@components/Heading';

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([] as Company[]);

  const updateCompanies = () => {
    setIsLoading(true);
    listCompanies().then((c) => {
      setCompanies(c);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    updateCompanies();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend'],
      sorter: (a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Stores',
      dataIndex: 'numberOfStores',
      key: 'numberOfStores',
    },
    {
      title: 'Users',
      dataIndex: 'numberOfUsers',
      key: 'numberOfUsers',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      render: (text: any, record: any) => (
        <Dropdown
          placement={'bottomRight'}
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      let data: AddCompanyModalState = {
                        id: record.id,
                        name: record.name,
                        onComplete: () => {
                          updateCompanies();
                        },
                      };

                      addCompanyModalState.set(data);
                    }}
                  >
                    <FaPencilAlt /> Edit
                  </a>
                ),
              },
              {
                key: '2',
                label: (
                  <a
                    href="#"
                    className={'text-danger'}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('delete', record);
                      if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                        setIsLoading(true);
                        deleteCompany(record.id).then(() => {
                          updateCompanies();
                        });
                      }
                    }}
                  >
                    <FaTrash /> Delete
                  </a>
                ),
              },
            ],
          }}
        >
          <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
            Action <FaCaretDown />
          </a>
        </Dropdown>
      ),
    },
  ].filter((x) => x !== null) as ColumnsType<Company>;

  return (
    <div style={{ padding: 20 }}>
      <Heading>Manage Companies</Heading>
      <Card
        title="Companies"
        extra={
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openNewCompanyModal(() => {
                updateCompanies();
              });
            }}
          >
            Add Company
          </a>
        }
      >
        <Table dataSource={companies} columns={columns} loading={isLoading} />
      </Card>
      <AddCompanyModal />
    </div>
  );
};
