export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let params = Object.fromEntries(urlSearchParams.entries());
  Object.keys(params).forEach((key) => {
    params[key] = decodeURIComponent(params[key]);
  });
  return params;
};

export const sendIframeMessage = (msg: any) => {
  try {
    if (window?.top?.postMessage) {
      window.top.postMessage(JSON.stringify(msg), '*');
    }
  } catch (e) {
    console.log(e);
  }
};
