import React, { useState, useRef } from 'react';
import { Col, Input, Modal, Row, Select } from 'antd';
import { createGlobalState } from 'react-global-hooks';
import useFromNull from '@utils/useFromNull';
import Store from '@interfaces/Store';
import { RolesEnum } from '@interfaces/Enums';
import listStores from '@api/context/company/store/listStores';
import currentUserState from '@globalState/currentUserState';
import listCompanies from '@api/context/company/listCompanies';
import { alertApiErrors } from '@utils/axiosErrors';
import addUser, { AdminOpt, Opt } from '@api/context/user/addUser';
import modifyUser from '@api/context/user/modifyUser';

export type AddUserModalState = {
  id?: number;
  stores: number[];
  firstName: string;
  username: string;
  lastName: string;
  role: keyof typeof RolesEnum;
  email: string;
  company?: number;
  password?: string;
  onComplete?: () => void | Promise<void>;
};

const emptyAddUserModal: AddUserModalState = {
  stores: [],
  username: '',
  firstName: '',
  lastName: '',
  password: '',
  role: 'STORE',
  email: '',
};

export const addUserModalState = createGlobalState(null as AddUserModalState | null);

export const openNewUserModal = (onComplete: () => void | Promise<void> = () => {}) => {
  const currentUser = currentUserState.get();
  let data: any = { ...emptyAddUserModal, onComplete };
  if (currentUser?.isStore || currentUser?.isMaster) {
    data.company = currentUser?.company?.id || -1;
  }
  addUserModalState.set(data);
};

const AddUserModal = () => {
  const [addUserModal, setAddUserModal] = addUserModalState.use();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const currentUser = currentUserState.useValue();
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [stores, setStores] = useState([] as Store[]);
  const [companyList, setCompanyList] = useState<{ id: number; name: string }[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const firstInputRef = useRef<any>(null);

  const isNew = !addUserModal?.id;

  const loadStores = async () => {
    setIsLoadingStores(true);
    const s = await listStores();
    setStores(s);
    setIsLoadingStores(false);
  };
  const loadCompanies = async () => {
    setIsLoadingCompanies(true);
    const c = await listCompanies();
    setCompanyList(c);
    setIsLoadingCompanies(false);
  };

  useFromNull(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 300);
    loadStores();
    if (currentUser?.isAdmin) {
      loadCompanies();
    }
  }, [addUserModal]);

  const handleCancel = () => {
    setAddUserModal(null);
  };
  const handleSubmit = async () => {
    setConfirmLoading(true);
    try {
      let opts: Opt | AdminOpt = {
        username: addUserModal?.username || '',
        firstName: addUserModal?.firstName || '',
        lastName: addUserModal?.lastName || '',
        email: addUserModal?.email || '',
        stores:
          addUserModal?.stores?.map((s) => {
            return { id: s };
          }) || [],
        isStore: addUserModal?.role === 'STORE',
        isMaster: addUserModal?.role === 'MASTER',
        company: { id: addUserModal?.company || -1 },
      };
      if (currentUser?.isAdmin) {
        // @ts-ignore
        opts.isAdmin = addUserModal?.role === 'ADMIN';
      }
      if (isNew) {
        opts.password = addUserModal?.password || '';
        await addUser(opts);
      } else {
        await modifyUser(addUserModal?.id || -1, opts);
      }
      if (addUserModal?.onComplete) {
        await addUserModal.onComplete();
      }
      // todo submit and refresh
      setConfirmLoading(false);
      setAddUserModal(null);
    } catch (e) {
      console.error(e);
      alertApiErrors(e);
      setConfirmLoading(false);
    }
  };
  const handleChange = (e: any) => {
    if (!addUserModal) return;
    setAddUserModal({
      ...addUserModal,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      title={`${isNew ? 'Add' : 'Edit'} User`}
      open={!!addUserModal}
      confirmLoading={confirmLoading}
      onOk={handleSubmit}
      okText={isNew ? 'Add' : 'Save'}
      onCancel={handleCancel}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        {currentUser?.isAdmin ? (
          <>
            <div>Company</div>
            <Select
              style={{ width: '100%' }}
              loading={isLoadingCompanies}
              value={addUserModal?.company}
              ref={firstInputRef}
              disabled={isLoadingCompanies || addUserModal?.role === 'ADMIN'}
              onChange={(value) => {
                if (!addUserModal) return;
                setAddUserModal({
                  ...addUserModal,
                  company: value,
                });
              }}
            >
              <Select.Option value={-1}> - Select One - </Select.Option>
              {companyList.map((company) => {
                return (
                  <Select.Option key={company.id} value={company.id}>
                    {company.name}
                  </Select.Option>
                );
              })}
            </Select>
            <div style={{ height: 20 }}></div>
          </>
        ) : null}

        <div>Stores</div>
        <Select
          style={{ width: '100%' }}
          loading={isLoadingStores}
          value={addUserModal?.stores}
          ref={firstInputRef}
          disabled={
            isLoadingStores || addUserModal?.role === 'ADMIN' || addUserModal?.role === 'MASTER'
          }
          mode="multiple"
          allowClear
          onChange={(value) => {
            if (!addUserModal) return;
            setAddUserModal({
              ...addUserModal,
              stores: value,
            });
          }}
        >
          <Select.Option value={-1}> - Select One - </Select.Option>
          {stores.map((store) => {
            return (
              <Select.Option key={store.id} value={store.id}>
                {store.name} <small>({store.company?.name ?? 'N/A'})</small>
              </Select.Option>
            );
          })}
        </Select>
        <div style={{ marginTop: 20 }}>First Name</div>
        <Input
          name={'firstName'}
          placeholder="John"
          value={addUserModal?.firstName ?? ''}
          onChange={handleChange}
        />
        <div style={{ marginTop: 20 }}>Last Name</div>
        <Input
          name={'lastName'}
          placeholder="Smith"
          value={addUserModal?.lastName ?? ''}
          onChange={handleChange}
        />
        <div style={{ marginTop: 20 }}>Role</div>
        <Select
          style={{ width: '100%' }}
          value={addUserModal?.role ?? ''}
          ref={firstInputRef}
          onChange={(value) => {
            if (!addUserModal) return;
            setAddUserModal({
              ...addUserModal,
              role: value as keyof typeof RolesEnum,
            });
          }}
        >
          <Select.Option value={''}> - Select One - </Select.Option>
          {
            //@ts-ignore
            Object.keys(RolesEnum).map((key: keyof typeof RolesEnum) => {
              if (key === 'ADMIN' && !currentUser?.isAdmin) return null;
              return (
                <Select.Option key={key} value={key}>
                  {RolesEnum[key]}
                </Select.Option>
              );
            })
          }
        </Select>
        <div style={{ marginTop: 20 }}>Email</div>
        <Input
          name={'email'}
          placeholder="john@doe.com"
          value={addUserModal?.email ?? ''}
          onChange={handleChange}
        />
        <div style={{ marginTop: 20 }}>Username</div>
        <Input
          name={'username'}
          placeholder="something"
          value={addUserModal?.username ?? ''}
          onChange={handleChange}
        />

        {isNew ? (
          <>
            <div style={{ marginTop: 20 }}>Initial Password</div>
            <Input
              name={'password'}
              placeholder="password"
              value={addUserModal?.password ?? ''}
              onChange={handleChange}
            />
          </>
        ) : null}
      </form>
    </Modal>
  );
};

export default AddUserModal;
