import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  name: string;
  city?: string;
  state?: string;
  company?: { id: number };
  managers?: { id: number }[];
}

const addStore = async (opt: Opt): Promise<any> => {
  const res = await axiosSecure.post(`${getContextRoot()}/store`, opt);
  return res.data;
};

export default addStore;
