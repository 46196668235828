import axiosSecure from '@api/axiosSecure';
import apiRoot from '@api/apiRoot';
import Notification from '@interfaces/Notification';

const deleteNotification = async (notificationId: string | number): Promise<Notification> => {
  const res = await axiosSecure.delete(`${apiRoot}/admin/notification/${notificationId}`);
  return res.data;
};

export default deleteNotification;
