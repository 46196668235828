import listLocations from "@api/context/wine/location/listLocations";
import Heading from "@components/Heading";
import currentUserState from "@globalState/currentUserState"
import Location from "@interfaces/Location";
import { useEffect, useState } from "react";
import CompanyStoreLocation from "./CompanyStoreLocation";
import AddLocationModal from "./AddLocationModal";

export default () => {

    const currentUser = currentUserState.useValue();
    const [isLoading, setIsLoading] = useState(false);
    const [globalLocation, setGlobalLocations] = useState([] as Location[]);

    const updateLocations = () => {
        setIsLoading(true);
        listLocations().then((loc: Location[]) => {
            const companyStoreLocation = loc.filter(
                (location) => location.type === 'CompanyLocation'
            );

            const globalLocation = loc.filter(
                (location) => location.type === 'GlobalLocation'
            );

            globalLocation.sort((a: any, b) => a.name.localeCompare(b.name))


            const storeLocations = loc.filter(
                (location) => location.type === 'StoreLocation'
            );

            // Reusable sorting function
            const sortByRegionAndName = (a: any, b: any) => {
                const nameComparison = a.name.localeCompare(b.name);
                if (nameComparison !== 0) {
                    return nameComparison;
                }

                const regionA = a.region || '';
                const regionB = b.region || '';
                return regionA.localeCompare(regionB);
            };

            globalLocation.sort(sortByRegionAndName);
            companyStoreLocation.sort(sortByRegionAndName);
            storeLocations.sort(sortByRegionAndName);

            if (currentUser?.isAdmin) {
                setGlobalLocations([...globalLocation, ...companyStoreLocation, ...storeLocations]);
            } else if (currentUser?.isMaster) {
                setGlobalLocations([...companyStoreLocation, ...storeLocations, ...globalLocation]);
            } else if (currentUser?.isStore) {
                setGlobalLocations([...storeLocations, ...companyStoreLocation, ...globalLocation]);
            }

            setIsLoading(false);
        });
    };

    useEffect(() => {
        updateLocations();
    }, []);

    return (
        <div style={{ padding: 20 }}>
            <Heading>Manage Locations</Heading>
            {currentUser?.isAdmin || currentUser?.isMaster || currentUser?.isStore ? (
                <>
                    <CompanyStoreLocation
                        locations={globalLocation}
                        updateLocations={updateLocations}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                    <div style={{ height: 20 }} />
                </>
            ) : null}
            <AddLocationModal />
        </div>
    );
}