import React, { useEffect, useMemo } from 'react';
import style from './Widget1.module.scss';
import UmamiIcon from './UmamiIcon';
import { getQueryParams } from '@utils/basic';
import YoutubeEmbed from '@components/YoutubeEmbed';
import useInterval from '@utils/useInterval';
import { sendIframeMessage } from '@utils/basic';
import getWidgetWine from '@api/widget/getWidgetWine';
import Wine from '@interfaces/Wine';
import { FaBan, FaCircleNotch } from 'react-icons/fa';

type TextAndIcon = {
  icon: string;
  text: string;
};

type WidgetData = {
  flavorProfile: string;
  foodPairing: TextAndIcon[];
  wineRegion: TextAndIcon;
  taste: string;
  videoUrl: string | null;
};

const demoData: WidgetData = {
  flavorProfile: 'Supple, medium bodied red',
  foodPairing: [
    { icon: '/images/icons/pasta.svg', text: 'Pasta with Red Sauce' },
    { icon: '/images/icons/cow.svg', text: 'Red Meat' },
    { icon: '/images/icons/duck.svg', text: 'Wild Game' },
  ],
  wineRegion: { icon: '/images/icons/australia.svg', text: 'South Eastern Australia' },
  taste:
    'Opens with aromas of lavender and boysenberry, giving way to flavors of dark cherry, pomegranate and vanilla. This luscious wine has a silky mouthfeel, framed by subtle hints of oak from barrel aging.',
  videoUrl: 'https://www.youtube.com/watch?v=HrjThXr0V4o',
};

const cleanThumbnailUrl = (url: string) => {
  // replace http  or https with //
  url = url.replace(/^https?:/, '');
  return url;
}

const Widget1 = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const [wine, setWine] = React.useState(null as Wine | null);

  const params = getQueryParams();
  const upcCode = params.upcCode || '';
  const backgroundColor = params.backgroundColor || '#ffffff';
  const fontColor = params.fontColor || '#333333';
  const iconColor = params.iconColor || '#979797';
  const borderColor = params.borderColor || '#cccccc';
  const featureWineName = params.featureWineName === 'true';
  const featureFlavorProfile = params.featureFlavorProfile === 'true';
  const featureFoodPairing = params.featureFoodPairing === 'true';
  const featureWineRegion = params.featureWineRegion === 'true';
  const featureTaste = params.featureTaste === 'true';
  const featureThumbnail = params.featureThumbnail === 'true';

  const featureDescription = 'featureDescription' in params && params.featureDescription === 'true';
  const featureVideo = params.featureVideo === 'true';
  const company = params.company ? Number(params.company) : null;
  const store = params.store ? Number(params.store) : null;
  let thumbSize = 256;

  // set thumbsize if passed in
  try {
    if (params.thumbSize) {
      thumbSize = Number(params.thumbSize);
    }
  } catch (err) {
    console.log('error parsing thumbSize', err);
  }
  console.log('params', params);

  useEffect(() => {
    setIsLoading(true);
    let p: any = {
      upcCode,
    };
    if (company) {
      p.company = company;
    }
    if (store) {
      p.store = store;
    }
    getWidgetWine(p)
      .then((data) => {
        console.log('data', data);
        setWine(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setIsLoading(false);
      });
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const lastHeightRef = React.useRef(0);

  useInterval(() => {
    const root = mainContainerRef.current;
    if (root) {
      const newHeight = root.offsetHeight;
      console.log('newHeight', newHeight);
      if (newHeight !== lastHeightRef.current) {
        lastHeightRef.current = newHeight;
        sendIframeMessage({ height: newHeight });
      }
    } else {
      console.log('root not found');
    }
  }, 300);

  const mainStyle: React.CSSProperties = {
    width: '100%',
    backgroundColor: backgroundColor,
    color: fontColor,
    paddingTop: '8px',
  };

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div style={{ color: fontColor }} className={style.loadingBox}>
          <div className={style.loadingIcon}>
            <div>
              <FaCircleNotch className={'spin'} />
            </div>
          </div>
          <div className={style.loadingText}>Loading...</div>
        </div>
      );
    }

    if (!wine) {
      return (
        <div style={{ color: fontColor }} className={style.loadingBox}>
          <div className={style.loadingIcon}>
            <FaBan /> Wine Not Found
          </div>
        </div>
      );
    }

    return (
      <>

        {featureThumbnail && wine?.thumbnailUrl ? (
          <div
            className={style.thumbBox}
            style={{width: thumbSize, height: thumbSize}}
          >
            <img src={cleanThumbnailUrl(wine.thumbnailUrl)} alt="" />
          </div>
        ) : null}

        {featureWineName && wine?.name ? (
          <div className={style.details}>
            <div className={style.title}>Wine Name</div>
            <div className={style.description}>{wine.name}</div>
          </div>
        ) : null}

        {featureFlavorProfile && wine?.flavorProfile?.name ? (
          <div className={style.details}>
            <div className={style.title}>Flavor Profile</div>
            <div className={style.description}>{wine.flavorProfile.name}</div>
          </div>
        ) : null}

        {featureFoodPairing && wine?.foodPairings?.length > 0 ? (
          <div className={`${style.details} ${style.foodPairings}`}>
            <div className={style.title}>Food Pairings</div>
            {wine.foodPairings
              .sort((a, b) => a.orderId - b.orderId)
              .map((item, index) => {
              return (
                <div key={index} className={style.description}>
                  <div className={style.description} style={{ color: iconColor }}>
                    {item.icon ? <img src={item.icon} alt="" /> : null}
                  </div>
                  <div className={style.description}>{item.name}</div>
                </div>
              );
            })}
          </div>
        ) : null}

        {featureWineRegion && wine?.location?.name ? (
          <div className={style.wineRegion}>
            <div className={style.title}>Wine Region</div>
            <div className={style.description}>
              <div className={style.icon} style={{ color: iconColor }}>
                {wine.location.icon ? <img src={wine.location.icon} alt="" /> : null}
              </div>
              <div className={style.description}>{wine.location.name}</div>
            </div>
          </div>
        ) : null}

        {featureTaste && wine?.taste ? (
          <div className={style.details}>
            <div className={style.title}>Taste</div>
            <div className={style.description}>{wine.taste}</div>
          </div>
        ) : null}

        {featureDescription && wine?.description ? (
          <div className={style.details}>
            <div className={style.title}>Description</div>
            <div className={style.description}>{wine.description}</div>
          </div>
        ) : null}

        <div className={style.clearfix}></div>

        {featureVideo && wine?.videoUrl ? (
          <div className={style.details}>
            <div className={style.title}>Video</div>
            <div className={style.video}>
              <YoutubeEmbed url={wine?.videoUrl} />
            </div>
          </div>
        ) : null}
      </>
    );
  }, [isLoading, wine]);

  return (
    <div style={mainStyle} ref={mainContainerRef}>
      <div className={style.container} style={{ borderColor: borderColor }}>
        <div className={style.poweredBy} style={{ background: backgroundColor }}>
          <div className={style.iconBox}>
            <UmamiIcon color={iconColor} />
          </div>
          <div className={style.textBox} style={{ color: iconColor }}>
            <div className={style.top}>Powered by</div>
            <div className={style.bottom}>Umami Wine Guide</div>
          </div>
        </div>
        {content}
        <div className={style.clearfix} />
      </div>
    </div>
  );
};

export default Widget1;
