import deleteProducer from '@api/context/wine/producer/deleteProducer';
import currentUserState from '@globalState/currentUserState';
import Producers from '@interfaces/Producers';
import { Card, Dropdown } from 'antd';
import { FaCaretDown, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { AddProducersModalState, addProducerModalState, openNewProducerModal } from './AddProducersModal';
import Table, { ColumnsType } from 'antd/es/table';

type Props = {
  producers: Producers[];
  updateProducer: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export default ({ producers, updateProducer, isLoading, setIsLoading }: Props) => {

  const currentUser = currentUserState.useValue();

  const unusedProducerColumns: ColumnsType<Producers> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Level',
      dataIndex: 'type',
      key: 'level',

      render: (text: any, record: Producers) => {
        let newData = "";

        if (record.type === 'GlobalProducer') {
          newData = "Global Producer"
        } else if (record.type === 'CompanyProducer') {
          newData = "Company Producer"
        } else if (record.type === 'StoreProducer') {
          newData = "Store Producer"
        }
        return <p>{newData}</p>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      fixed: 'right',
      render: (text: any, record: Producers) => {
        let showActions = false;

        // admins can edit and delete everything
        if (currentUser?.isAdmin) {
          showActions = true;
        }

        if (currentUser?.isMaster && record.type=='CompanyProducer' || record.type=='StoreProducer') {
          showActions = true;
        }

        if (currentUser?.isStore && record.type=='StoreProducer') {
          showActions = true;
        }

        if (!showActions) {
          return null;
        }
        return (
          <Dropdown
            placement={'bottomRight'}
            menu={{
              items: [
                {
                  key: `${record.id}a`,
                  label: (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        let data: AddProducersModalState = {
                          id: record.id,
                          name: record.name ?? '',
                          onComplete: updateProducer,
                          type: record.type,
                          wine: null
                        };

                        addProducerModalState.set(data);
                      }}
                    >
                      <FaPencilAlt /> Edit
                    </a>
                  ),
                },
                {
                  key: `${record.id}b`,
                  label: (
                    <a
                      href="#"
                      className={'text-danger'}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log('delete', record);
                        if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                          setIsLoading(true);
                          deleteProducer(record.id).then(() => {
                            updateProducer();
                          });
                        }
                      }}
                    >
                      <FaTrash /> Delete
                    </a>
                  ),
                },
              ],
            }}
          >
            <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
              Action <FaCaretDown />
            </a>
          </Dropdown>
        );
      },
    },
  ];


  return (
    <>
      <Card
        title={'Producers'}
        extra={
          <>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openNewProducerModal(updateProducer);
              }}
            >
              Create Producer
            </a>
          </>
        }
      >
        <Table
          dataSource={producers}
          rowKey="id"
          columns={unusedProducerColumns}
          loading={isLoading}
          scroll={{ x: 1300 }}
        />
      </Card>
    </>
  );
}