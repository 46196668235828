import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

interface FoodPairings {
  id: number;
  name: string | null;
}

export interface Opt {
  id: number;
  name: string;
  type: string;
  foodPairings: FoodPairings[];
  icon: string;
  wines?: { id: number };
}
const modifyFoodPairing = async (foodPairingId: string | number, opt: Opt): Promise<any> => {
    const res = await axiosSecure.put(`${getContextRoot()}/foodPairing/${foodPairingId}`, opt);
  return res.data;
};

export default modifyFoodPairing;
