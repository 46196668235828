import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

const listFlavors = async (): Promise<any> => {
  const res = await axiosSecure.get(`${getContextRoot()}/flavorProfile`);
  // const res = await axiosSecure.get(`${getContextRoot()}/wine?max=25&offset=0`);
  return res.data;
};

export default listFlavors;
