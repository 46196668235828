import React, { useState } from 'react';
import style from './Login.module.scss';
import { Button, Form, Input } from 'antd';
import { AiFillLock, AiOutlineUser } from 'react-icons/ai';
import loginUser from '@api/authentication/loginUser';

export default () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null as string[] | null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    setErrors(null);
    setIsLoading(true);
    const errors = await loginUser(username, password);
    if (errors) {
      setErrors(errors);
    }
    setIsLoading(false);
  };
  return (
    <div className={style.login}>
      <div className={style.loginContainer}>
        <div className={style.loginHeader}>
          <img src="/images/umami-icon.png" alt="logo" />
          Umami Wine
        </div>
        <div className={style.loginBody}>
          <Form onSubmitCapture={handleSubmit}>
            {errors ? (
              <div className={style.error}>
                {errors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
            ) : null}
            <Form.Item>
              <Input
                prefix={<AiOutlineUser />}
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Input
                prefix={<AiFillLock />}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#000',
                }}
                // disabled={isLoading}
                loading={isLoading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
