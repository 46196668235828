import React from 'react';
import style from './Heading.module.scss';

type Props = {
  children?: React.ReactNode;
};
const Heading = ({ children }: Props) => {
  return <div className={style.heading}>{children}</div>;
};

export default Heading;
