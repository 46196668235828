import listVarietals from "@api/context/wine/varietal/listVarietals";
import Heading from "@components/Heading";
import currentUserState from "@globalState/currentUserState";
import Varietals from "@interfaces/Varietals";
import { useEffect, useState } from "react";
import CompanyStoreVarietals from "./CompanyStoreVarietals";
import AddVarietalModal from "./AddVarietalModal";

export default () => {
  const currentUser = currentUserState.useValue();
  const [isLoading, setIsLoading] = useState(false);
  const [globalVarietals, setGlobalVarietals] = useState([] as Varietals[]);

  const updateVarietals = () => {
    setIsLoading(true);
    listVarietals().then((c: Varietals[]) => {
      const companyStoreVarietals = c.filter(
        (varietal) => varietal.type === 'CompanyVarietal'
      );
      companyStoreVarietals.sort((a: any, b: any) => a.name.localeCompare(b.name))
      const globalVarietals = c.filter(
        (varietal) =>
          varietal.type === 'GlobalVarietal'
      );
      globalVarietals.sort((a: any, b: any) => a.name.localeCompare(b.name))

      const storeVarietals = c.filter(
        (varietal) => varietal.type === 'StoreVarietal'
      );
      storeVarietals.sort((a: any, b: any) => a.name.localeCompare(b.name))

      if (currentUser?.isAdmin) {
        setGlobalVarietals([...globalVarietals, ...companyStoreVarietals, ...storeVarietals]);
      } else if (currentUser?.isMaster) {
        setGlobalVarietals([...companyStoreVarietals, ...storeVarietals, ...globalVarietals]);
      } else if (currentUser?.isStore) {
        setGlobalVarietals([...storeVarietals, ...companyStoreVarietals, ...globalVarietals]);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    updateVarietals();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <Heading>Manage Varietals</Heading>
      {currentUser?.isAdmin || currentUser?.isMaster || currentUser?.isStore ? (
        <>
          <CompanyStoreVarietals
            varietals={globalVarietals}
            updateVarietals={updateVarietals}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <div style={{ height: 20 }} />
        </>
        
      ) : null}
      <AddVarietalModal/>
    </div>
  );
}