import React from 'react';
import style from './WidgetDesigner.module.scss';
import { ChromePicker } from 'react-color';
import { Button, Popover } from 'antd';

type Props = {
  value: string;
  onChange: (value: string) => void;
};
const ColorBox = ({ value, onChange }: Props) => {
  return (
    <>
      <Popover
        placement="bottomLeft"
        title={'Pick a color'}
        content={<ChromePicker color={value} onChange={(color) => onChange(color.hex)} />}
        trigger="click"
      >
        <div className={style.colorBox} style={{ background: value }} />
      </Popover>
    </>
  );
};

export default ColorBox;
