import React from 'react';
import Heading from '@components/Heading';
import { Button, Input } from 'antd';
import addNotification from '@api/notification/addNotification';

const { TextArea } = Input;

type Props = {
  triggerUpdate: () => void | Promise<void>;
};

const AddNotificationsComponent = ({ triggerUpdate }: Props) => {
  const [title, setTitle] = React.useState('' as string);
  const [text, setText] = React.useState('' as string);
  const [isLoading, setIsLoading] = React.useState(false);

  const triggerAdd = async () => {
    setIsLoading(true);
    await addNotification({
      title: title,
      content: text,
    });
    setText('');
    setTitle('');
    await triggerUpdate();
    setIsLoading(false);
  };
  return (
    <div>
      <Heading>Add Notifications</Heading>
      <div>
        <Input
          placeholder="Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          disabled={isLoading}
        />
        <div style={{ height: 20 }}></div>
        <TextArea
          rows={4}
          value={text}
          placeholder="Text"
          onChange={async (e) => {
            setText(e.target.value);
          }}
          disabled={isLoading}
        />
        <div style={{ margin: '15px 0 40px' }}>
          <Button type="primary" loading={isLoading} onClick={triggerAdd}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddNotificationsComponent;
