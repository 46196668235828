import apiRoot from '@api/apiRoot';
import rolesState from '@globalState/rolesState';

const getContextRoot = () => {
  const role = rolesState.get();
  if (role?.isAdmin) {
    return `${apiRoot}/admin`;
  }
  if (role?.isMaster) {
    return `${apiRoot}/master`;
  }

  if (role?.isStore) {
    return `${apiRoot}/store`;
  }

  return `${apiRoot}`;
};

export default getContextRoot;
