import addFoodPairing, { Opt } from "@api/context/wine/foodPairing/addFoodPairing";
import listFoodPairings from "@api/context/wine/foodPairing/listFoodPairings";
import modifyFoodPairing from "@api/context/wine/foodPairing/modifyFoodPairing";
import NameAndId from "@interfaces/NameAndId";
import { alertApiErrors } from "@utils/axiosErrors";
import useFromNull from "@utils/useFromNull";
import { Input, Modal, Select } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { createGlobalState } from "react-global-hooks";

export type AddFlavorFoodPairingModalState = {
    id?: number;
    name: string;
    type: string;
    foodPairings: any[];
    onComplete?: () => void | Promise<void>;
};

const emptyAddUserModal: AddFlavorFoodPairingModalState = {
    name: '',
    type: '',
    foodPairings: []
};

export const addFlavorFoodPairingModalState = createGlobalState(null as AddFlavorFoodPairingModalState | null);

export const openNewFoodPairingModal = (onComplete: () => void | Promise<void> = () => { }) => {
    addFlavorFoodPairingModalState.set({ ...emptyAddUserModal, onComplete: onComplete });
};

const TextInput = React.forwardRef(
    (
        {
            name,
            label,
            handleChange,
            type = 'input',
        }: {
            name: string;
            label: string;
            handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
            type?: 'input' | 'textarea';
        },
        ref
    ) => {
        const addFlavorFoodPairingModal = addFlavorFoodPairingModalState.useValue();
        return (
            <div style={{ marginTop: 20 }}>
                {label}

                <Input
                    name={name}
                    placeholder={label}
                    //@ts-ignore
                    value={addFlavorFoodPairingModal?.[name] ?? ''}
                    onChange={handleChange}
                    //@ts-ignore
                    ref={ref}
                />

            </div>
        );
    }
);

const AddFlavorFoodPairingModal = () => {
    const [addFlavorFoodPairingModal, setAddFlavorFoodPairingModal] = addFlavorFoodPairingModalState.use();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [flavorFoodPairing, setFlavorFoodPairing] = useState([] as NameAndId[]);
    const [isLoading, setIsLoading] = useState(false);
    const isNew = !addFlavorFoodPairingModal?.id;

    const firstInputRef = useRef<any>(null);

    useFromNull(() => {
        if (addFlavorFoodPairingModal) {
            setTimeout(() => {
                if (firstInputRef.current) {
                    firstInputRef.current.focus();
                }
            }, 300);
            setIsLoading(true);
            listFoodPairings().then((foodPairings) => {
                setFlavorFoodPairing(foodPairings);
                setIsLoading(false);
            }).catch((error) => {
                alertApiErrors(error);
                setIsLoading(false);
            })
            setIsLoading(true);
        }
    }, [addFlavorFoodPairingModal]);

    const handleCancel = () => {
        setAddFlavorFoodPairingModal(null);
    };

    const handleSubmit = async () => {
        if (!addFlavorFoodPairingModal) return;
        setConfirmLoading(true);
        let foodPairings: any = [];
        try {

            addFlavorFoodPairingModal.foodPairings.map((c, index) => {
                foodPairings.push({ id: c.id , name: c.name, orderId: index + 1 });
            })
            let opts: Opt = {
                name: addFlavorFoodPairingModal.name ?? '',
                foodPairings: foodPairings ?? '',
                id: 0,
                type: "",
                icon: ""    
            };
            if (isNew) {
                // todo add company for admins
                await addFoodPairing(opts);
            } else {
                // todo add company for admins
                await modifyFoodPairing(addFlavorFoodPairingModal.id as number, opts);
                // await modifyFoodPairing(addProducerModal.id as number, opts);
            }
            if (addFlavorFoodPairingModal?.onComplete) {
                await addFlavorFoodPairingModal.onComplete();
            }
            setConfirmLoading(false);
            setAddFlavorFoodPairingModal(null);
        } catch (e) {
            setConfirmLoading(false);
            alertApiErrors(e);
        }
    };

    const handleChange = (e: any) => {
        if (!addFlavorFoodPairingModal) return;
        setAddFlavorFoodPairingModal({
            ...addFlavorFoodPairingModal,
            [e.target.name]: e.target.value,
        });
    };

    const handleFoodChange = (e: any) => {
        if (!addFlavorFoodPairingModal) return;
        const foodPairingObj: any = [];
    
        e?.target?.value?.forEach((selectedFood: string) => {
          const matchingFood = flavorFoodPairing.find((food) => food.name === selectedFood);
    
          if (matchingFood) {
            foodPairingObj.push({
              id: matchingFood.id,
              name: matchingFood.name,
            });
          } else {
            foodPairingObj.push({
              id: null,
              name: selectedFood,
            });
          }
        });
    
        setAddFlavorFoodPairingModal({
          ...addFlavorFoodPairingModal,
          [e.target.id]: e.target.id,
          [e.target.name]: e.target.value,
          foodPairings: foodPairingObj,
        });
      };
    

    const SelectFoodPairing = ({
        name,
        label,
        options,
        handleFoodChange,
        isMulti = false,
    }: {
        name: string;
        label: string;
        options: any[];
        handleFoodChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        isMulti?: boolean;
    }) => {

        const addFlavorFoodPairingModal = addFlavorFoodPairingModalState.useValue();

        
        const groupOptions = useMemo(() => {

            const groupNames: string[] = [];
            options?.forEach((option) => {
                if (option.name && option.name !== '' && !groupNames.includes(option.name)) {
                    groupNames.push(option.name);
                }
            });

            let groups: any = [];

            groupNames.forEach((groupName) => {
                groups.push({
                    label: groupName,
                    value: groupName,
                });
            });
            return groups;

        }, [options]);

        return (
            <div style={{ marginTop: 20 }}>
                {label}
                <Select
                    mode="tags"
                    allowClear={isMulti ? true : undefined}
                    placeholder={label}
                    //@ts-ignore
                    value={addFlavorFoodPairingModal?.['foodPairings'].map((item) => { return item.name }) ?? ''}
                    style={{ width: '100%' }}
                    showSearch={true}
                    onChange={(value) => {
                        const syntheticEvent = {
                            target: {
                                name,
                                value,
                            },

                        }
                        handleFoodChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);

                    }}
                    options={groupOptions}
                />
            </div>
        );
    };

    return (
        <Modal
            title={`${isNew ? 'Add' : 'Edit'} FoodPairing `}
            open={!!addFlavorFoodPairingModal}
            confirmLoading={confirmLoading} 
            onOk={handleSubmit}
            okText={'Save'}
            onCancel={handleCancel}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}
            >
                <div style={{ opacity: isLoading ? 0.5 : 1 }}>
                    <TextInput name={'name'} label={'Flavor Profile Name'} handleChange={handleChange} />

                    <SelectFoodPairing
                        name={'foodPairings'}
                        label={'Food Pairings'}
                        options={flavorFoodPairing}
                        handleFoodChange={handleFoodChange}
                    />
                </div>
            </form>
        </Modal>
    );

};

export default AddFlavorFoodPairingModal;

