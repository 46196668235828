import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  name?: string;
  city?: string;
  state?: string;
  company?: { id: number };
  managers?: { id: number }[];
}

const modifyStore = async (storeId: number | string, opt: Opt): Promise<any> => {
  const res = await axiosSecure.put(`${getContextRoot()}/store/${storeId}`, opt);
  return res.data;
};

export default modifyStore;
