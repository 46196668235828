import React from 'react';
import Heading from '@components/Heading';
import style from './WidgetDesigner.module.scss';
import { Input, Switch } from 'antd';
import { v4 as uuid } from 'uuid';
import useOnMessage from '../../utils/useOnMessage';
import ColorBox from '@pages/WidgetDesigner/ColorBox';
import currentUserState from '@globalState/currentUserState';

type FormInfo = {
  featureWineName: any;
  upcCode: string;
  backgroundColor: string;
  fontColor: string;
  iconColor: string;
  borderColor: string;
  featureFlavorProfile: boolean;
  featureFoodPairing: boolean;
  featureWineRegion: boolean;
  featureTaste: boolean;
  featureVideo: boolean;
  featureThumbnail: boolean;
  thumbSize: number;
  featureDescription: boolean;
};

const blankFormInfo: FormInfo = {
  upcCode: '123456789012',
  backgroundColor: '#ffffff',
  fontColor: '#333333',
  iconColor: '#979797',
  borderColor: '#cccccc',
  featureWineName: true,
  featureFlavorProfile: true,
  featureFoodPairing: true,
  featureWineRegion: true,
  featureTaste: true,
  featureVideo: true,
  featureThumbnail: true,
  thumbSize: 256,
  featureDescription: true
};

export default () => {
  const [widgetForm, setWidgetForm] = React.useState<FormInfo>(blankFormInfo);
  const [iframeHeight, setIframeHeight] = React.useState(500);
  const currentUser = currentUserState.useValue();

  const iframeUrl = React.useMemo(() => {
    let url = `${window.location.origin}/widget1?upcCode=${encodeURIComponent(
      widgetForm.upcCode
    )}&backgroundColor=${encodeURIComponent(
      widgetForm.backgroundColor
    )}&fontColor=${encodeURIComponent(widgetForm.fontColor)}&iconColor=${encodeURIComponent(
      widgetForm.iconColor
    )}&borderColor=${encodeURIComponent(
      widgetForm.borderColor
    )}&featureWineName=${encodeURIComponent(
      widgetForm.featureWineName ? 'true' : 'false'
    )}&featureFlavorProfile=${encodeURIComponent(
      widgetForm.featureFlavorProfile ? 'true' : 'false'
    )}&featureFoodPairing=${encodeURIComponent(
      widgetForm.featureFoodPairing ? 'true' : 'false'
    )}&featureWineRegion=${encodeURIComponent(
      widgetForm.featureWineRegion ? 'true' : 'false'
    )}&featureThumbnail=${encodeURIComponent(
      widgetForm.featureThumbnail ? 'true' : 'false'
    )}&featureDescription=${encodeURIComponent(
      widgetForm.featureDescription ? 'true' : 'false'
    )}&featureTaste=${encodeURIComponent(
      widgetForm.featureTaste ? 'true' : 'false'
    )}&thumbSize=${encodeURIComponent(
      '' + widgetForm.thumbSize
    )}&featureVideo=${encodeURIComponent(widgetForm.featureVideo ? 'true' : 'false')}`;
    if (currentUser?.isMaster && currentUser.company) {
      url += `&company=${encodeURIComponent(currentUser.company.id)}`;
    }
    if (currentUser?.isStore && currentUser.company && currentUser.stores?.length > 0) {
      url += `&company=${encodeURIComponent(currentUser.company.id)}&store=${encodeURIComponent(
        currentUser.stores[0].id
      )}`;
    }
    return url;
  }, [widgetForm]);

  const htmlCode = React.useMemo(() => {
    return `<iframe scrolling="no" id="umami-widget1" style="border: none; width: 100%; height: 500px;" src="${iframeUrl}"></iframe>
<script async src="${window.location.origin}/widget.js"></script>`;
  }, [iframeUrl]);

  const iframeRefreshKey = React.useMemo(() => {
    return uuid();
  }, [iframeUrl]);

  const iframeComponent = React.useMemo(() => {
    return (
      <iframe
        scrolling="no"
        style={{ border: 'none', width: '100%', height: iframeHeight }}
        src={`${iframeUrl}`}
        key={iframeRefreshKey}
      ></iframe>
    );
  }, [iframeUrl, iframeRefreshKey, iframeHeight]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    // handle thumb size
    if (name === 'thumbSize') {
      // strip non numeric characters
      value = value.replace(/\D/g, '');
      let parsedValue = parseInt(value);
      if (isNaN(parsedValue)) {
        parsedValue = 0
      }
      // min of 20, max of 800
      if (parsedValue < 1) {
        parsedValue = 1;
      } else if (parsedValue > 800) {
        parsedValue = 800;
      }
      setWidgetForm({ ...widgetForm, [name]: parsedValue });
      return;
    }
    setWidgetForm({ ...widgetForm, [name]: value });
  };

  useOnMessage((message) => {
    console.log('message', message);
    if ('height' in message) {
      setIframeHeight(message.height);
    }
  });

  return (
    <div className={style.container}>
      <Heading>Widget Designer</Heading>
      <div className={style.row}>
        <div className={style.left}>
          <div className={style.label}>
            UPC Code <small>(Unique to each wine)</small>
          </div>
          <Input placeholder="0000000000" value={widgetForm.upcCode} onChange={(event) => {

            setWidgetForm({ ...widgetForm, upcCode: event.target.value });
          }} />

          <hr />

          <div className={style.label}>Background Color</div>
          <Input
            name={'backgroundColor'}
            placeholder="#ffffff"
            value={widgetForm.backgroundColor}
            onChange={handleChange}
            prefix={
              <ColorBox
                value={widgetForm.backgroundColor}
                onChange={(value) => {
                  setWidgetForm({ ...widgetForm, backgroundColor: value });
                }}
              />
            }
          />

          <div className={style.label}>Font Color</div>
          <Input
            name={'fontColor'}
            placeholder="#333333"
            value={widgetForm.fontColor}
            onChange={handleChange}
            prefix={
              <ColorBox
                value={widgetForm.fontColor}
                onChange={(value) => {
                  setWidgetForm({ ...widgetForm, fontColor: value });
                }}
              />
            }
          />

          <div className={style.label}>Icon Color</div>
          <Input
            name={'iconColor'}
            placeholder="#979797"
            value={widgetForm.iconColor}
            onChange={handleChange}
            prefix={
              <ColorBox
                value={widgetForm.iconColor}
                onChange={(value) => {
                  setWidgetForm({ ...widgetForm, iconColor: value });
                }}
              />
            }
          />

          <div className={style.label}>Border Color</div>
          <Input
            name={'borderColor'}
            placeholder="#cccccc"
            value={widgetForm.borderColor}
            onChange={handleChange}
            prefix={
              <ColorBox
                value={widgetForm.borderColor}
                onChange={(value) => {
                  setWidgetForm({ ...widgetForm, borderColor: value });
                }}
              />
            }
          />

          <div className={style.label}>Features</div>
          
          <div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureWineName}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureWineName: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>Wine Name</div>
          </div>

          <div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureFlavorProfile}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureFlavorProfile: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>Flavor Profile</div>
          </div>

          <div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureFoodPairing}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureFoodPairing: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>Food Pairing</div>
          </div>

          <div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureWineRegion}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureWineRegion: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>Wine Region</div>
          </div>

          <div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureTaste}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureTaste: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>Taste</div>
          </div><div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureDescription}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureDescription: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>Description</div>
          </div>
          <div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureThumbnail}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureThumbnail: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>
              Thumbnail <small>(if available)</small>
            </div>
          </div>

          <div className={style.label}>Thumbnail Size</div>
          <Input
            name={'thumbSize'}
            type="number"
            placeholder="256"
            value={widgetForm.thumbSize}
            onChange={handleChange}
          />

          <div className={style.toggleBox}>
            <div className={style.switchBox}>
              <Switch
                checked={widgetForm.featureVideo}
                onChange={(checked) => {
                  setWidgetForm({ ...widgetForm, featureVideo: checked });
                }}
              />
            </div>
            <div className={style.toggleText}>
              Video <small>(if available)</small>
            </div>
          </div>
          <hr />
          <div className={style.label}>HTML Code</div>
          <pre>{htmlCode}</pre>
        </div>
        <div className={style.right}>{iframeComponent}</div>
      </div>
    </div>
  );
};
