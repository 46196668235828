import React, { useState, useRef } from 'react';
import { Col, Input, Modal, Row, Select } from 'antd';
import { createGlobalState } from 'react-global-hooks';
import useFromNull from '@utils/useFromNull';
import { delay } from '@utils/basic';
import stateOptions from '@utils/stateOptions';
import addStore from '@api/context/company/store/addStore';
import modifyStore from '@api/context/company/store/modifyStore';
import currentUserState from '@globalState/currentUserState';
import { alertApiErrors } from '@utils/axiosErrors';
import { devLog } from '@utils/devLog';
import listCompanies from '@api/context/company/listCompanies';

export type AddStoreModalState = {
  id?: number;
  name: string;
  city: string;
  state: string;
  company: number;
  onComplete?: () => void | Promise<void>;
};

const emptyAddStoreModalState: AddStoreModalState = {
  name: '',
  city: '',
  state: 'AL',
  company: -1,
};

export const addStoreModalState = createGlobalState(null as AddStoreModalState | null);

export const openNewStoreModal = (onComplete: () => void | Promise<void> = () => {}) => {
  const currentUser = currentUserState.get();
  let data: any = { ...emptyAddStoreModalState, onComplete };
  if (currentUser?.isStore || currentUser?.isMaster) {
    data.company = currentUser?.company?.id || -1;
  }
  addStoreModalState.set(data);
};

const AddStoreModal = () => {
  const [addStoreModal, setAddStoreModal] = addStoreModalState.use();
  const currentUser = currentUserState.useValue();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [companyList, setCompanyList] = useState<{ id: number; name: string }[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const firstInputRef = useRef<any>(null);

  devLog('AddStoreModal', addStoreModal);

  const isNew = !addStoreModal?.id;

  useFromNull(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 300);

    if (currentUser?.isAdmin) {
      // todo get companies for admins
      setIsLoadingCompanies(true);
      listCompanies().then((companies) => {
        setCompanyList(companies);
        setIsLoadingCompanies(false);
      });
    }
  }, [addStoreModal]);

  const handleCancel = () => {
    setAddStoreModal(null);
  };
  const handleSubmit = async () => {
    if (!addStoreModal) return;
    setConfirmLoading(true);
    try {
      if (isNew) {
        // todo add company for admins
        await addStore({
          company: { id: addStoreModal?.company },
          name: addStoreModal?.name,
          city: addStoreModal?.city,
          state: addStoreModal?.state,
        });
      } else {
        // todo add company for admins
        await modifyStore(addStoreModal.id as number, {
          company: { id: addStoreModal?.company },
          name: addStoreModal?.name,
          city: addStoreModal?.city,
          state: addStoreModal?.state,
        });
      }
      if (addStoreModal?.onComplete) {
        await addStoreModal.onComplete();
      }
      setConfirmLoading(false);
      setAddStoreModal(null);
    } catch (e) {
      setConfirmLoading(false);
      alertApiErrors(e);
    }
  };
  const handleChange = (e: any) => {
    if (!addStoreModal) return;
    setAddStoreModal({
      ...addStoreModal,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      title={`${isNew ? 'Add' : 'Edit'} Store`}
      open={!!addStoreModal}
      confirmLoading={confirmLoading}
      onOk={handleSubmit}
      okText={`${isNew ? 'Add' : 'Save'}`}
      onCancel={handleCancel}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <div>Store Name</div>
        <Input
          ref={firstInputRef}
          name={'name'}
          placeholder="Name"
          value={addStoreModal?.name ?? ''}
          onChange={handleChange}
        />
        {currentUser?.isAdmin ? (
          <>
            <div style={{ height: 20 }} />
            <div>Company</div>
            <Select
              placeholder="Company"
              style={{ width: '100%' }}
              options={[
                { label: '- Select One -', value: -1 },
                ...companyList.map((company) => ({
                  label: company.name,
                  value: company.id,
                })),
              ]}
              value={addStoreModal?.company ?? -1}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                if (!addStoreModal) return;
                setAddStoreModal({
                  ...addStoreModal,
                  company: value,
                });
              }}
              loading={isLoadingCompanies}
            />
          </>
        ) : null}
        <div style={{ height: 20 }} />
        <Input.Group>
          <Row gutter={8}>
            <Col span={16}>
              <div>City</div>
              <Input
                name={'city'}
                placeholder="City"
                value={addStoreModal?.city ?? ''}
                onChange={handleChange}
              />
            </Col>
            <Col span={8}>
              <div>State</div>
              <Select
                placeholder="State"
                style={{ width: '100%' }}
                options={stateOptions}
                value={addStoreModal?.state ?? 'AL'}
                onChange={(value) => {
                  // @ts-ignore
                  setAddStoreModal({ ...addStoreModal, state: `${value}` });
                }}
              />
            </Col>
          </Row>
        </Input.Group>
      </form>
    </Modal>
  );
};

export default AddStoreModal;
