import Heading from "@components/Heading";
import currentUserState from "@globalState/currentUserState";
import { useEffect, useState } from "react";
import CompanyStoreProducers from "./CompanyStoreProducers";
import Producers from "@interfaces/Producers";
import listProducer from "@api/context/wine/producer/listProducer";
import GlobalProducers from "./GlobalProducers";
import AddProducerModal from "./AddProducersModal";

export default () => {
  const currentUser = currentUserState.useValue();
  const [isLoading, setIsLoading] = useState(false);
  const [globalProducers, setGlobalProducer] = useState([] as Producers[]);

  const [companyStoreProducers, setCompanyStoreProducers] = useState([] as Producers[]);

  const updateProducer = () => {
    setIsLoading(true);
    listProducer().then((c: Producers[]) => {
      const companyStoreProducers = c.filter(
        (producers) => producers.type === 'CompanyProducer'
      );

      companyStoreProducers.sort((a: any, b: any) => a.name.localeCompare(b.name))

      const globalProducers = c.filter(
        (producers) =>
          producers.type === 'GlobalProducer'
      );
      globalProducers.sort((a: any, b: any) => a.name.localeCompare(b.name))

      const storeProducer = c.filter(
        (producers) => producers.type === 'StoreProducer'
      );
      storeProducer.sort((a: any, b: any) => a.name.localeCompare(b.name))

      if (currentUser?.isAdmin) {
        setGlobalProducer([...globalProducers, ...companyStoreProducers, ...storeProducer]);
      } else if (currentUser?.isMaster) {
        setGlobalProducer([...companyStoreProducers, ...storeProducer, ...globalProducers]);
      } else if (currentUser?.isStore) {
        setGlobalProducer([...storeProducer, ...companyStoreProducers, ...globalProducers]);
      }
      setCompanyStoreProducers(companyStoreProducers);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    updateProducer();
  }, []);
  return (
    <div style={{ padding: 20 }}>
      <Heading>Manage Producer</Heading>
      {currentUser?.isMaster || currentUser?.isStore  || currentUser?.isAdmin ? (
        <>
          <CompanyStoreProducers
            producers={globalProducers}
            updateProducer={updateProducer}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <div style={{ height: 20 }} />
        </>
      ) : null}
      <AddProducerModal />
    </div>
  );
};