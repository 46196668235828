import { createGlobalState } from 'react-global-hooks';
import CurrentUser from '@interfaces/CurrentUser';

const currentUserState = createGlobalState(null as null | CurrentUser);

export const currentUserTickState = createGlobalState(0);

export const updateCurrentUser = () => {
  currentUserTickState.set(currentUserTickState.get() + 1);
};

export default currentUserState;
