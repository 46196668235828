import axios from 'axios';
import authState from '@globalState/authState';

const axiosSecure = axios.create(); // add auth

axiosSecure.interceptors.request.use(
  (config) => {
    // add auth header to request
    const auth = authState.get();
    if (!config.headers) {
      //@ts-ignore
      config.headers = {};
    }
    // Do something before request is sent
    config.headers['Authorization'] = `Bearer ${auth ? auth.access_token : ''}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosSecure.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response) {
      // handle unauthorized. DeAuthenticate user
      if (error.response.status === 401) {
        authState.set(null);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosSecure;
