import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  name?: string;
  region?: string;
  subRegion?: string;
  wines?: { id: number };
  country?: string;
  usState?: string;
  additionalInformation?: string;
}

const modifyLocation = async (locationId: string | number, opt: Opt): Promise<any> => {
  const res = await axiosSecure.put(`${getContextRoot()}/location/${locationId}`, opt);
  return res.data;
};

export default modifyLocation;
