import React from 'react';
import currentUserState, { currentUserTickState } from '@globalState/currentUserState';
import rolesState from '@globalState/rolesState';
import getCurrentUser from '@api/user/getCurrentUser';

const StateManager = () => {
  const user = currentUserState.useValue();
  const currentUserTick = currentUserTickState.useValue();

  // handles setting the roles state when user is modified
  React.useEffect(() => {
    if (user) {
      rolesState.set({
        isAdmin: user.isAdmin,
        isStore: user.isStore,
        isMaster: user.isMaster,
      });
    } else {
      rolesState.set(null);
    }
  }, [user]);

  React.useEffect(() => {
    getCurrentUser().then((user) => {
      if (user) {
        currentUserState.set(user);
      } else {
        currentUserState.set(null);
      }
    });
  }, [currentUserTick]);

  return <></>;
};

export default StateManager;
