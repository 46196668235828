import React, { useState, useRef, useMemo } from 'react';
import { Col, Input, Modal, Row, Select } from 'antd';
import { createGlobalState } from 'react-global-hooks';
import useFromNull from '@utils/useFromNull';
import NameAndId from '@interfaces/NameAndId';
import listProducer from '@api/context/wine/producer/listProducer';
import listVarietals from '@api/context/wine/varietal/listVarietals';
import listFlavors from '@api/context/wine/flavor/listFlavors';
import listFoodPairings from '@api/context/wine/foodPairing/listFoodPairings';
import listLocations from '@api/context/wine/location/listLocations';
import { alertApiErrors } from '@utils/axiosErrors';
import addWine from '@api/context/wine/addWine';
import modifyWines, { Opt } from '@api/context/wine/modifyWines';
import TextArea from 'antd/lib/input/TextArea';
import listFlavorById from '@api/context/wine/flavor/listFlavorById';
import FlavorProfile from '@interfaces/FlavorProfile';

export type AddWineModalState = {
  id?: number;
  videoUrl: string;
  name: string;
  thumbnailUrl?: string;
  upcCode?: string;
  description?: string;
  taste: string;
  producer: number;
  varietal: number;
  flavorProfile: any;
  location: number;
  foodPairings: any[];
  onComplete?: () => void | Promise<void>;
  vintage: string;
};

const emptyAddUserModal: AddWineModalState = {
  upcCode: '',
  name: '',
  description: '',
  taste: '',
  producer: -1,
  varietal: -1,
  flavorProfile: -1,
  location: -1,
  foodPairings: [],
  thumbnailUrl: '',
  videoUrl: '',
  vintage: '',
};

export const addWineModalState = createGlobalState(null as AddWineModalState | null);

export const openNewWineModal = (onComplete: () => void | Promise<void> = () => { }) => {
  addWineModalState.set({ ...emptyAddUserModal, onComplete: onComplete });
};

const TextInput = React.forwardRef(
  (
    {
      name,
      label,
      handleChange,
      type = 'input',
    }: {
      name: string;
      label: string;
      handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
      type?: 'input' | 'textarea';
    },
    ref
  ) => {
    const addWineModal = addWineModalState.useValue();
    return (
      <div style={{ marginTop: 20 }}>
        {label}
        {type === 'textarea' ? (
          <TextArea
            name={name}
            placeholder={label}
            //@ts-ignore
            value={addWineModal?.[name] ?? ''}
            onChange={handleChange}
            //@ts-ignore
            ref={ref}
          />
        ) : (
          <Input
            name={name}
            placeholder={label}
            //@ts-ignore
            value={addWineModal?.[name] ?? ''}
            onChange={handleChange}
            //@ts-ignore
            ref={ref}
          />
        )}
      </div>
    );
  }
);

const SelectInput = ({
  name,
  label,
  options,
  handleChange,
  isMulti = false,
}: {
  name: string;
  label: string;
  options: NameAndId[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMulti?: boolean;
}) => {
  const addWineModal = addWineModalState.useValue();
  return (
    <div style={{ marginTop: 20 }}>
      {label}
      <Select
        mode={isMulti ? 'multiple' : undefined}
        allowClear={isMulti ? true : undefined}
        placeholder={label}
        //@ts-ignore
        value={addWineModal?.[name] ?? ''}
        style={{ width: '100%' }}
        showSearch={true}
        filterOption={(input, option) => {
          // @ts-ignore
          return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={(value) => {
          //@ts-ignore
          handleChange({ target: { name, value } });
        }}
        options={[
          { label: ' -- Select One -- ', value: -1 },
          ...options.map((option) => ({ label: option.name, value: option.id })),
        ]}
      />
    </div>
  );
};

const SelectRegion = ({
  name,
  label,
  options,
  handleChange,
  isMulti = false,
}: {
  name: string;
  label: string;
  options: any[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMulti?: boolean;
}) => {
  const addWineModal = addWineModalState.useValue();
  const groupOptions = useMemo(() => {
    const groupNames: string[] = [];
    options?.forEach((option) => {
      if (option.region && option.region !== '' && !groupNames.includes(option.region)) {
        groupNames.push(option.region);
      }
    });

    // sort group names alphabetically
    groupNames.sort((a, b) => {
      if (a < b) return -1;
      else if (a > b) return 1;
      else return 0;
    });

    const getOption = (option: any, groupName: string | null) => {
      let searchString = `${groupName} ${option.name}`;
      let label = `${option.name}`;
      if (option.subRegion) {
        searchString += ` ${option.subRegion}`;
        label += ` - ${option.subRegion}`;
      }
      if (option.country) {
        searchString += ` ${option.country}`;
        label += ` - ${option.country}`;
      }
      if (option.usState) {
        searchString += ` ${option.usState}`;
        label += ` - ${option.usState}`;
      }
      if (option.additionalInformation) {
        searchString += ` ${option.additionalInformation}`;
        label += ` - ${option.additionalInformation}`;
      }
      return {
        label: `${label}`,
        value: option.id,
        search: `${searchString}`,
      };
    };
    let groups: any = [];
    // add default
    groups.push({ label: ' -- Select One -- ', value: -1 });
    groupNames.forEach((groupName) => {

      const filteredSortedOptions = options
        .filter((option) => option.region === groupName)
        .map((option) => {
          return getOption(option, groupName);
        }).sort((a, b) => {
          if (a.search < b.search) return -1;
          else if (a.search > b.search) return 1;
          else return 0;
        });
      groups.push({
        label: groupName,
        options: filteredSortedOptions,
      });
    });

    // add ones without region
    groups.push({
      label: 'No Region',
      options: options
        .filter((option) => !option.region || option.region === '')
        .map((option) => {
          return getOption(option, null);
        }),
    });
    return groups;
  }, [options]);
  return (
    <div style={{ marginTop: 20 }}>
      {label}
      <Select
        mode={isMulti ? 'multiple' : undefined}
        allowClear={isMulti ? true : undefined}
        placeholder={label}
        //@ts-ignore
        value={addWineModal?.[name] ?? ''}
        style={{ width: '100%' }}
        showSearch={true}
        filterOption={(input, option) => {
          // @ts-ignore
          const inputParts = input
            .toLowerCase()
            .split(' ')
            .map((part) => part.trim())
            .filter((part) => part.length > 0);
          if (inputParts.length === 0) {
            // not searching
            return true;
          }
          // @ts-ignore
          const optionSearch = option?.search?.toLowerCase() ?? '';

          return inputParts.every((part) => optionSearch.includes(part));
        }}
        onChange={(value) => {
          //@ts-ignore
          handleChange({ target: { name, value } });
        }}
        options={groupOptions}
      />
    </div>
  );
};

const AddWineModal = () => {
  const [addWineModal, setAddWineModal] = addWineModalState.use();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [producers, setProducers] = useState([] as NameAndId[]);
  const [varietals, setVarietals] = useState([] as NameAndId[]);
  const [flavorProfiles, setFlavorProfiles] = useState([] as NameAndId[]);
  const [locations, setLocations] = useState([] as NameAndId[]);
  const [foodPairings, setFoodPairings] = useState<NameAndId[]>([{ name: '-- Select One --', id: -1 }]);

  const isNew = !addWineModal?.id;

  const firstInputRef = useRef<any>(null);

  useFromNull(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 300);
    setIsLoading(true);
    Promise.all([
      listProducer(),
      listVarietals(),
      listFlavors(),
      listLocations(),
      listFoodPairings(),
    ])
      .then(([producers, varietals, flavorProfiles, locations, foodPairings]) => {
        setProducers(producers);
        setVarietals(varietals);
        setFlavorProfiles(flavorProfiles);
        setLocations(locations);
        setFoodPairings(foodPairings.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '')));
        setIsLoading(false);
      })
      .catch((err) => {
        alertApiErrors(err);
        setIsLoading(false);
      });
    
    if (addWineModal && addWineModal.foodPairings) {
      const sortedFoodPairings = addWineModal.foodPairings.sort(
        (a, b) => a.orderId - b.orderId
      );

      setAddWineModal({
        ...addWineModal,
        foodPairings: sortedFoodPairings,
      });
    }
  }, [addWineModal]);

  const handleCancel = () => {
    setAddWineModal(null);
  };
  const handleSubmit = async () => {
    if (!addWineModal) return;
    setConfirmLoading(true);
    try {
      let foodPairings: any = [];

      addWineModal.foodPairings.map((c, index) => {
        console.log(c.name)
        foodPairings.push({ id: c.id, name: c.name, orderId: index + 1 });
      })
      let opts: Opt = {
        upcCode: addWineModal.upcCode ?? '',
        name: addWineModal.name ?? '',
        description: addWineModal.description ?? '',
        taste: addWineModal.taste ?? '',
        producer:
          addWineModal.producer && addWineModal.producer > -1
            ? { id: addWineModal.producer }
            : undefined,
        varietal:
          addWineModal.varietal && addWineModal.varietal > -1
            ? { id: addWineModal.varietal }
            : undefined,
        flavorProfile:
          addWineModal.flavorProfile && addWineModal.flavorProfile > -1
            ? { id: addWineModal.flavorProfile }
            : undefined,
        location:
          addWineModal.location && addWineModal.flavorProfile > -1
            ? { id: addWineModal.location }
            : undefined,
        vintage: addWineModal.vintage ?? '',
        foodPairings: foodPairings,
        thumbnailUrl: addWineModal.thumbnailUrl ?? '',
        videoUrl: addWineModal.videoUrl ?? '',
      };
      if (isNew) {
        // todo add company for admins
        await addWine(opts);
      } else {
        // todo add company for admins
        await modifyWines(addWineModal.id as number, opts);
      }
      if (addWineModal?.onComplete) {
        await addWineModal.onComplete();
      }
      setConfirmLoading(false);
      setAddWineModal(null);
    } catch (e) {
      setConfirmLoading(false);
      alertApiErrors(e);
    }
  };
  const handleChange = (e: any) => {
    if (!addWineModal) return;
    setAddWineModal({
      ...addWineModal,
      [e.target.name]: e.target.value,
    });
  };

  const handleFoodChange = (e: any) => {
    if (!addWineModal) return;
    const foodPairingObj: any = [];

    e?.target?.value?.forEach((selectedFood: string) => {
      const matchingFood = foodPairings.find((food) => food.name === selectedFood);

      if (matchingFood) {
        foodPairingObj.push({
          id: matchingFood.id,
          name: matchingFood.name,
        });
      } else {
        foodPairingObj.push({
          id: null,
          name: selectedFood,
        });
      }
    });

    setAddWineModal({
      ...addWineModal,
      [e.target.id]: e.target.id,
      [e.target.name]: e.target.value,
      foodPairings: foodPairingObj,
    });
  };


  const SelectFavorProfile = ({
    name,
    label,
    options,
    handleChange,
    isMulti = false,
  }: {
    name: string;
    label: string;
    options: any[];
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isMulti?: boolean;
  }) => {

    const addWineModal = addWineModalState.useValue();
    const groupOptions = useMemo(() => {
      const groupNames: string[] = [];
      const groupValues: any = {};
      options?.forEach((option) => {
        if (option.type && option.type !== '' && !groupNames.includes(option.type)) {
          groupNames.push(option.type);
          groupValues[`${option.type}`] = [];
        }
        groupValues[option.type].push({ label: option.name, value: option.id })
      });
      console.log(groupValues);
      let groups: any = [{ label: '', options: [{ label: '-- Select One --', value: -1 }] }];
      // add default
      // groups.push({ label: ' -- Select One -- ', value: -1 });
      Object.keys(groupValues).forEach((type: any) => {
        groups.push({
          label: type,
          options: groupValues[type],
        });
      });
      return groups;
    }, [options]);

    return (
      <div style={{ marginTop: 20 }}>
        {label}
        <Select
          mode={isMulti ? 'multiple' : undefined}
          allowClear={isMulti ? true : undefined}
          placeholder={label}
          //@ts-ignore
          value={addWineModal?.[name] ?? ''}
          style={{ width: '100%' }}
          showSearch={true}
          filterOption={(input, option) => {
            // @ts-ignore
            return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}

          onChange={(value) => {

            listFlavorById(value).then((flavorProfiles: FlavorProfile) => {
              const filteredFoodPairings = flavorProfiles.foodPairings
                ? flavorProfiles.foodPairings.filter((item) => item.name !== null).map((item) => ({
                  id: item.id,
                  name: item.name!,
                  orderId: item.orderId,
                }))
                : [];

              addWineModal!.foodPairings = filteredFoodPairings.sort((a: any, b: any) => a.orderId - b.orderId)
              addWineModal!.flavorProfile = value
              addWineModalState.set(addWineModal)
            });

            const syntheticEvent = {
              target: {
                name,
                value,
              },
            }
            handleChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);

          }}
          options={groupOptions}
        />
      </div>
    );
  };

  const SelectFoodPairing = ({
    name,
    label,
    options,
    handleFoodChange,
    isMulti = false,
  }: {
    name: string;
    label: string;
    options: any[];
    handleFoodChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isMulti?: boolean;
  }) => {

    const addWineModal = addWineModalState.useValue();


    const groupOptions = useMemo(() => {

      const groupNames: string[] = [];
      const foodPairings: any[] = [];

      options?.forEach((option) => {
        if (option.name && option.name !== '' && !groupNames.includes(option.name)) {
          groupNames.push(option.name);
        }
        if (addWineModal?.foodPairings != null) {
          const matchingFoodPairing = addWineModal?.foodPairings?.find(
            (pairing) => pairing.orderId === option.orderId
          );
          if (matchingFoodPairing) {
            foodPairings.push({
              label: option.name,
              value: option.name,
            });
          }
        }
      });

      let groups: any = [];

      groupNames.forEach((groupName) => {
        groups.push({
          label: groupName,
          value: groupName,
        });
      });
      return groups;

    }, [options]);

    return (
      <div style={{ marginTop: 20 }}>
        {label}
        <Select
          mode="tags"
          allowClear={isMulti ? true : undefined}
          placeholder={label}
          //@ts-ignore
          value={addWineModal?.['foodPairings']?.map((item) => { return item.name }) ?? ''}
          style={{ width: '100%' }}
          showSearch={true}
          onChange={(value) => {
            const syntheticEvent = {
              target: {
                name,
                value,
              },

            }
            handleFoodChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);

          }}
          options={groupOptions}
        />
      </div>
    );
  };


  return (
    <Modal
      title={`${isNew ? 'Add' : 'Edit'} Wine`}
      open={!!addWineModal}
      confirmLoading={confirmLoading}
      onOk={handleSubmit}
      okText={'Save'}
      onCancel={handleCancel}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <div style={{ opacity: isLoading ? 0.5 : 1 }}>
          <TextInput
            name={'upcCode'}
            label={'UPC Code'}
            ref={firstInputRef}
            handleChange={handleChange}
          />
          <TextInput name={'name'} label={'Wine Name'} handleChange={handleChange} />
          <TextInput name={'description'} label={'Description'} handleChange={handleChange} />
          <TextInput name={'taste'} label={'Taste'} handleChange={handleChange} />

          <SelectInput
            name={'producer'}
            label={'Producer'}
            options={producers}
            handleChange={handleChange}
          />
          <SelectInput
            name={'varietal'}
            label={'Varietal'}
            options={varietals}
            handleChange={handleChange}
          />
          <SelectFavorProfile
            name={'flavorProfile'}
            label={'Flavor Profile'}
            options={flavorProfiles}
            handleChange={handleChange}
          />
          <TextInput name={'vintage'} label={'Vintage'} handleChange={handleChange} />
          <SelectRegion
            name={'location'}
            label={'Location'}
            options={locations}
            handleChange={handleChange}
          />
          {/* <SelectInput
            name={'foodPairings'}
            label={'Food Pairings'}
            //@ts-ignore
            options={foodPairings}
            handleChange={handleChange}
            isMulti={true}
          /> */}

          <SelectFoodPairing
            name={'foodPairings'}
            label={'Food Pairings'}
            options={foodPairings}
            handleFoodChange={handleFoodChange}
          />

          <TextInput name={'thumbnailUrl'} label={'Thumbnail URL'} handleChange={handleChange} />
          <TextInput name={'videoUrl'} label={'Video URL'} handleChange={handleChange} />
        </div>
      </form>
    </Modal>
  );
};

export default AddWineModal;
