import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  name?: string;
  wines?: { id: number };
}

const addProducer = async (opt: Opt): Promise<any> => {
  const res = await axiosSecure.post(`${getContextRoot()}/producer`, opt);
  return res.data;
};

export default addProducer;
