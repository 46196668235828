import React, { useState, useEffect } from 'react';
import Heading from '@components/Heading';
import AddWineModal from '@pages/ManageWines/AddWineModal';
import listWines from '@api/context/wine/listWines';
import Wine from '@interfaces/Wine';
import GlobalWines from '@pages/ManageWines/GlobalWines';
import currentUserState from '@globalState/currentUserState';
import CompanyStoreWines from '@pages/ManageWines/CompanyStoreWines';

export default () => {
  const currentUser = currentUserState.useValue();
  const [isLoading, setIsLoading] = useState(false);
  const [globalWines, setGlobalWines] = useState([] as Wine[]);
  const [companyStoreWines, setCompanyStoreWines] = useState([] as Wine[]);

  const updateWines = () => {
    setIsLoading(true);
    listWines().then((c: Wine[]) => {
      const companyStoreWines = c.filter(
        (wine) => wine.type === 'CompanyWine' || wine.type === 'StoreWine'
      );
      const globalWines = c.filter(
        (wine) =>
          wine.type === 'GlobalWine'
      );
      setGlobalWines(globalWines);
      setCompanyStoreWines(companyStoreWines);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    updateWines();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <Heading>Manage Wines</Heading>
      {currentUser?.isMaster || currentUser?.isStore ? (
        <>
          <CompanyStoreWines
            wines={companyStoreWines}
            updateWines={updateWines}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <div style={{ height: 20 }} />
        </>
      ) : null}

      <GlobalWines
        wines={globalWines}
        updateWines={updateWines}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <AddWineModal />
    </div>
  );
};
