import axiosSecure from '@api/axiosSecure';
import apiRoot from '@api/apiRoot';
import Notification from '@interfaces/Notification';

const listNotifications = async (): Promise<Notification[]> => {
  const res = await axiosSecure.get(`${apiRoot}/user/notification`);
  return res.data;
};

export default listNotifications;
