import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  name?: string;
  stores?: { id: number }[];
}

const modifyCompany = async (companyId: number | string, opt: Opt): Promise<any> => {
  const res = await axiosSecure.put(`${getContextRoot()}/company/${companyId}`, opt);
  return res.data;
};

export default modifyCompany;
