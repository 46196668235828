import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  name: string;
  stores?: { id: number };
}

const addCompany = async (opt: Opt): Promise<any> => {
  const res = await axiosSecure.post(`${getContextRoot()}/company`, opt);
  return res.data;
};

export default addCompany;
