import currentUserState from "@globalState/currentUserState";
import { useEffect, useState } from "react";
import FlavorProfile from "@interfaces/FlavorProfile";
import Heading from "@components/Heading";
import AddFlavorFoodPairingModal from "./AddFlavorFoodPairing";
import listFlavors from "@api/context/wine/flavor/listFlavors";
import FlavorFoodPairingDetails from "./FlavorFoodPairingDetails";
import { alertApiErrors } from "@utils/axiosErrors";

export default () => {
    const currentUser = currentUserState.useValue();
    const [isLoading, setIsLoading] = useState(false);
    const [globalFlavorProfile, setGlobalFlavorProfile] = useState([] as FlavorProfile[]);

    const updateFlavorProfile = () => {

        setIsLoading(true);
        listFlavors().then((c: FlavorProfile[]) => {

            const companyFlavorProfile = c.filter(
                (flavorProfile) => flavorProfile.type === 'CompanyFlavorProfile'
            );
            const globalFlavorProfile = c.filter(
                (flavorProfile) =>
                    flavorProfile.type === 'GlobalFlavorProfile'
            );

            const storeFlavorProfile = c.filter(
                (flavorProfile) => flavorProfile.type === 'StoreFlavorProfile'
            );

            if (currentUser?.isAdmin) {
                setGlobalFlavorProfile([...globalFlavorProfile, ...companyFlavorProfile, ...storeFlavorProfile]);
            } else if (currentUser?.isMaster) {
                setGlobalFlavorProfile([...companyFlavorProfile, ...storeFlavorProfile, ...globalFlavorProfile]);
            } else if (currentUser?.isStore) {
                setGlobalFlavorProfile([...storeFlavorProfile, ...companyFlavorProfile, ...globalFlavorProfile]);
            }
            setIsLoading(false);
        }
        ).catch((error) => {
            alertApiErrors(error);
            setIsLoading(false);
        })
    };

    useEffect(() => {
        updateFlavorProfile();
    }, []);


    return (
        <div style={{ padding: 20 }}>
            <Heading>Manage Food Pairing</Heading>
            {currentUser?.isMaster || currentUser?.isStore || currentUser?.isAdmin ? (
                <>
                    <FlavorFoodPairingDetails
                        flavorFoodDetails={globalFlavorProfile}
                        updateFlavorProfile={updateFlavorProfile}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                    <div style={{ height: 20 }} />
                </>
            ) : null}
            <AddFlavorFoodPairingModal />
        </div>
    );
};