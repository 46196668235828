import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

/**
 * useFromNull will fire only when one of the dependencies in the dependency list
 * goes from null to a non null state.
 *
 * @param effect
 * @param deps
 */
const useFromNull = (effect: () => void, deps: React.DependencyList) => {
  const savedEffect = useRef(effect);
  const [isNullArr, setIsNullArr] = useState(deps.map((x) => x === null));

  // Remember the latest effect if it changes.
  useLayoutEffect(() => {
    savedEffect.current = effect;
  }, [effect]);

  useEffect(() => {
    let shouldTrigger = false;
    let shouldUpdate = false;
    for (let i = 0; i < deps.length; i++) {
      if ((deps[i] === null) !== isNullArr[i]) {
        // update the is null arr
        shouldUpdate = true;
        // check if came from null
        if (isNullArr[i]) {
          // one of our dependencies is exiting from null. Fire effect
          shouldTrigger = true;
        }
      }
    }
    if (shouldTrigger) {
      savedEffect.current();
    }
    if (shouldUpdate) {
      setIsNullArr(deps.map((x) => x === null));
    }
  }, deps);
};

export default useFromNull;
