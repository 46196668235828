import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  upcCode?: string;
  flavorProfile?: { id: number };
  varietal?: { id: number };
  location?: { id: number };
  producer?: { id: number };
  name?: string;
  description?: string;
  thumbnailUrl?: string;
  foodPairings?: { id: number ,name : string , orderId: number}[];
  taste?: string;
  videoUrl?: string;
  vintage?: string;
}

const modifyWines = async (wineId: string | number, opt: Opt): Promise<any> => {
  const res = await axiosSecure.put(`${getContextRoot()}/wine/${wineId}`, opt);
  return res.data;
};

export default modifyWines;
