import React, { useState, useEffect } from 'react';
import Heading from '@components/Heading';
import { Card, Table, Dropdown } from 'antd';
import Store from '@interfaces/Store';
import { ColumnsType } from 'antd/lib/table';
import AddStoreModal, {
  addStoreModalState,
  openNewStoreModal,
} from '@pages/ManageStores/AddStoreModal';
import listStores from '@api/context/company/store/listStores';
import { FaCaretDown, FaPencilAlt, FaTrash } from 'react-icons/fa';
import deleteStore from '@api/context/company/store/deleteStore';
import currentUserState from '@globalState/currentUserState';

export default () => {
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [stores, setStores] = useState([] as Store[]);
  const currentUser = currentUserState.useValue();

  const loadStores = () => {
    setIsLoadingStores(true);
    listStores().then((s) => {
      setStores(s);
      setIsLoadingStores(false);
    });
  };
  useEffect(() => {
    loadStores();
  }, []);

  const storeColumns: ColumnsType<Store> = [
    {
      title: 'Store Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    //@ts-ignore
    currentUser?.isAdmin
      ? {
          title: 'Company',
          dataIndex: ['company', 'name'],
          key: 'company.name',
        }
      : null,
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Total Wines',
      dataIndex: 'totalWines',
      key: 'totalWines',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      render: (text: any, record: any) => (
        <Dropdown
          placement={'bottomRight'}
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      const currentUser = currentUserState.get();
                      let data: any = {
                        id: record.id,
                        name: record.name,
                        city: record.city,
                        state: record.state,
                        company: record.company.id,
                        onComplete: () => {
                          loadStores();
                        },
                      };
                      if (currentUser?.isStore || currentUser?.isMaster) {
                        data.company = currentUser?.company?.id || -1;
                      }

                      addStoreModalState.set(data);
                    }}
                  >
                    <FaPencilAlt /> Edit
                  </a>
                ),
              },
              {
                key: '2',
                label: (
                  <a
                    href="#"
                    className={'text-danger'}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('delete', record);
                      if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                        setIsLoadingStores(true);
                        deleteStore(record.id).then(() => {
                          loadStores();
                        });
                      }
                    }}
                  >
                    <FaTrash /> Delete
                  </a>
                ),
              },
            ],
          }}
        >
          <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
            Action <FaCaretDown />
          </a>
        </Dropdown>
      ),
    },
  ].filter((c) => c !== null) as ColumnsType<Store>;

  return (
    <div style={{ padding: 20 }}>
      <Heading>Manage Store</Heading>
      <Card
        title="Stores"
        extra={
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openNewStoreModal(() => {
                loadStores();
              });
            }}
          >
            Add Store
          </a>
        }
      >
        <Table dataSource={stores} columns={storeColumns} loading={isLoadingStores} rowKey="id" />
      </Card>
      <AddStoreModal />
    </div>
  );
};
