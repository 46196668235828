import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

const listLocations = async (): Promise<any> => {
  const res = await axiosSecure.get(`${getContextRoot()}/location`);
  // const res = await axiosSecure.get(`${getContextRoot()}/location?max=25&offset=0`);
  return res.data;
};

export default listLocations;
