import { useEffect, useLayoutEffect, useRef } from 'react';

const useOnMessage = (callback: (message: any) => void, attachRaw: boolean = false) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const method = (e: MessageEvent<any>) => {
      try {
        // console.log('got message', e)
        const message = JSON.parse(e.data);
        if (attachRaw) {
          message.raw = e;
        }
        savedCallback.current(message);
      } catch (e) {
        // console.error(e);
      }
    };
    window.addEventListener('message', method);

    return () => window.removeEventListener('message', method);
  }, []);
};

export default useOnMessage;
