import axiosSecure from "@api/axiosSecure";
import getContextRoot from "@api/getContextRoot";


export interface Opt {
    id: number;
    name: string;
    type: string;
    foodPairings: FoodPairings[];
    icon: string;
    wines?: { id: number };
  }
  
  interface FoodPairings {
    id: number;
    name: string | null;
  }
  
const addFoodPairing = async (opt: Opt): Promise<any> => {

    const res = await axiosSecure.post(`${getContextRoot()}/foodPairing`, opt);
    return res.data;
};

export default addFoodPairing;
