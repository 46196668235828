import React, { useMemo } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu } from 'antd';
import {
  AiOutlineDashboard,
  AiOutlineHighlight,
  AiOutlineLogout,
  AiOutlineProfile,
  AiOutlineTable,
  AiOutlineUser,
} from 'react-icons/ai';
import { IoBusiness } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import style from './ApplicationLayout.module.scss';
import currentUserState from '@globalState/currentUserState';
import authState from '@globalState/authState';
import { FaStoreAlt, FaUsers, FaCloudUploadAlt, FaUbuntu, FaUikit, FaUmbraco, FaUtensils } from 'react-icons/fa';

const { Header, Content, Sider } = Layout;

const sidebarWidth = 200;

type Props = {
  children?: React.ReactNode;
};

type MenuItem = {
  key: string;
  label: string;
  icon: React.ReactNode;
};

const ApplicationLayout = ({ children }: Props) => {
  let location = useLocation();
  const currentUser = currentUserState.useValue();

  const menuItems = useMemo(() => {
    return [
      {
        key: '/dashboard',
        icon: <AiOutlineDashboard />,
        label: 'Dashboard',
      },
      currentUser?.isAdmin
        ? {
            key: '/manage-companies',
            icon: <IoBusiness />,
            label: 'Companies',
          }
        : null,
      currentUser?.isAdmin
        ? {
            key: '/upload',
            icon: <FaCloudUploadAlt />,
            label: 'Upload',
          }
        : null,
      currentUser?.isAdmin || currentUser?.isMaster
        ? {
            key: '/manage-users',
            icon: <FaUsers />,
            label: 'Users',
          }
        : null,
      currentUser?.isAdmin || currentUser?.isMaster
        ? {
            key: '/manage-stores',
            icon: <FaStoreAlt />,
            label: 'Stores',
          }
        : null,
      {
        key: '/manage-foodPairing',
        icon: <FaUtensils  />,
        label: 'Food Pairing',
      },
      {
        key: '/manage-wines',
        icon: <AiOutlineTable />,
        label: 'Wines',
      },
      {
        key: '/manage-producers',
        icon: <AiOutlineTable />,
        label: 'Producers',
      },
      {
        key: '/manage-varietals',
        icon: <AiOutlineTable />,
        label: 'Varietals',
      },
      {
        key: '/manage-locations',
        icon: <AiOutlineTable />,
        label: 'Locations',
      },
      // {
      //   key: '/manage-upsells',
      //   icon: <MdSell />,
      //   label: 'Upsells',
      // },
      {
        key: '/widget-designer',
        icon: <AiOutlineHighlight />,
        label: 'Widget Designer',
      },
      {
        key: '/account',
        icon: <AiOutlineUser />,
        label: 'Account',
      },
    ].filter((x) => x !== null) as MenuItem[];
  }, []);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          rel="noopener noreferrer"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            authState.set(null);
          }}
        >
          <div className={style.dropdownIcon}>
            <AiOutlineLogout />
          </div>
          Logout
        </a>
      ),
    },
  ];

  const name = React.useMemo(() => {
    if (currentUser?.firstName || currentUser?.lastName) {
      return `${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}`;
    }
    return 'Current User';
  }, [currentUser]);

  return (
    <Layout>
      <Header>
        <div className={style.logo} style={{ width: sidebarWidth }}>
          <img alt={'umami-icon'} src={'/images/umami-icon.svg'} /> Umami Wine
        </div>
        <div className={style.headerRight}>
          <Dropdown menu={{ items }} placement="bottomRight">
            <div className={style.headerRightItem}>
              <div className={style.icon}>
                <AiOutlineUser />
              </div>
              <span>{name}</span>
            </div>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider width={sidebarWidth} className={style.Sider}>
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{ height: '100%', borderRight: 0, background: '#000' }}
          >
            {menuItems.map((item) => {
              return (
                <Menu.Item key={item.key} icon={item.icon}>
                  <Link to={item.key}>{item.label}</Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
        <Layout style={{ padding: 0, margin: 0 }}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ApplicationLayout;
