import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

const listProducer = async (): Promise<any> => {
  const res = await axiosSecure.get(`${getContextRoot()}/producer`);
  // const res = await axiosSecure.get(`${getContextRoot()}/producer?max=25&offset=0`);
  return res.data;
};

export default listProducer;
