import React from 'react';
import { ConfigProvider, theme } from 'antd';
import colors from './colors';

type Props = {
  children?: React.ReactNode;
};
const Theme = ({ children }: Props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.umamiGold,
          colorLink: colors.umamiGold,
          colorLinkHover: colors.umamiGold,
          colorLinkActive: colors.umamiGold,
        },
        components: {
          Layout: {
            colorBgHeader: '#000',
          },
          Menu: {
            colorItemBgSelected: colors.umamiGold,
            colorItemTextSelected: '#000',
            colorItemTextHover: colors.umamiGold,
            colorItemText: colors.umamiGold,
            colorSubItemBg: '#000',
            radiusItem: 0,
            colorItemBgHover: '#000',
          },
          Table: {},
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
export default Theme;
