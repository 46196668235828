import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  upcCode?: string;
  flavorProfile?: { id: number };
  varietal?: { id: number };
  location?: { id: number };
  producer?: { id: number };
  name?: string;
  description?: string;
  thumbnailUrl?: string;
  foodPairings?: { id: number, name : string }[];
  taste?: string;
  videoUrl?: string;
  vintage?: string;
}

const addWine = async (opt: Opt): Promise<any> => {
  const res = await axiosSecure.post(`${getContextRoot()}/wine`, opt);
  return res.data;
};

export default addWine;
