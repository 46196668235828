import deleteProducer from '@api/context/wine/producer/deleteProducer';
import currentUserState from '@globalState/currentUserState';
import { Card, Dropdown } from 'antd';
import { FaCaretDown, FaPencilAlt, FaTrash } from 'react-icons/fa';
import Table, { ColumnsType } from 'antd/es/table';
import Varietals from '@interfaces/Varietals';
import { AddVarietalModalState, addVarietalModalState, openNewVarietalModal } from './AddVarietalModal';
import deleteVarietal from '@api/context/wine/varietal/deleteVarietal';

type Props = {
  varietals: Varietals[];
  updateVarietals: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export default ({ varietals, updateVarietals, isLoading, setIsLoading }: Props) => {

  const currentUser = currentUserState.useValue();

  const unusedVarietalColumns: ColumnsType<Varietals> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Level',
      dataIndex: 'type',
      key: 'level',
      
      render: (text: any, record: Varietals) => {
        let varietalType = "";

        if (record.type === 'GlobalVarietal') {
          varietalType = "Global Varietal"
        } else if (record.type === 'CompanyVarietal') {
          varietalType = "Company Varietal"
        } else if (record.type === 'StoreVarietal') {
          varietalType = "Store Varietal"
        }
        return <p>{varietalType}</p>;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      fixed: 'right',
      render: (text: any, record: Varietals) => {
        let showActions = false;

        // admins can edit and delete everything
        if (currentUser?.isAdmin) {
          showActions = true;
        }
        if (currentUser?.isMaster && record.type=='CompanyVarietal' || record.type=='StoreVarietal') {
          showActions = true;
        }

        if (currentUser?.isStore && record.type=='StoreVarietal') {
          showActions = true;
        }


        if (!showActions) {
          return null;
        }
        return (
          <Dropdown
            placement={'bottomRight'}
            menu={{
              items: [
                {
                  key: `${record.id}a`,
                  label: (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        let data: AddVarietalModalState = {
                          id: record.id,
                          name: record.name ?? '',
                          onComplete: updateVarietals,
                          type: record.type,
                          wines: null
                        };

                        addVarietalModalState.set(data);
                      }}
                    >
                      <FaPencilAlt /> Edit
                    </a>
                  ),
                },
                {
                  key: `${record.id}b`,
                  label: (
                    <a
                      href="#"
                      className={'text-danger'}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log('delete', record);
                        if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                          setIsLoading(true);
                          deleteVarietal(record.id).then(() => {
                            updateVarietals();
                          });
                        }
                      }}
                    >
                      <FaTrash /> Delete
                    </a>
                  ),
                },
              ],
            }}
          >
            <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
              Action <FaCaretDown />
            </a>
          </Dropdown>
        );
      },
    },
  ];


  return (
    <>
      <Card
        title={'Varietals'}
        extra={
          <>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openNewVarietalModal(updateVarietals);
              }}
            >
              Create Varietal
            </a>
          </>
        }
      >
        <Table
          dataSource={varietals}
          columns={unusedVarietalColumns}
          loading={isLoading}
          scroll={{ x: 1300 }}
          rowKey="id"
        />
      </Card>
    </>
  );
}