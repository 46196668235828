import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Card, Dropdown, Table } from 'antd';
import {
  AddWineModalState,
  addWineModalState,
  openNewWineModal,
} from '@pages/ManageWines/AddWineModal';
import { FaCaretDown, FaPencilAlt, FaTrash } from 'react-icons/fa';
import deleteWine from '@api/context/wine/deleteWine';
import Wine from '@interfaces/Wine';
import currentUserState from '@globalState/currentUserState';

type Props = {
  wines: Wine[];
  updateWines: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};
export default ({ wines, updateWines, isLoading, setIsLoading }: Props) => {
  const currentUser = currentUserState.useValue();

  const unusedWineColumns: ColumnsType<Wine> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Producer',
      key: 'producer.name',
      dataIndex: ['producer', 'name'],
    },
    {
      title: 'Varietal',
      dataIndex: ['varietal', 'name'],
      key: 'varietal.name',
    },
    {
      title: 'Region',
      dataIndex: ['location', 'name'],
      key: 'location.name',
    },
    {
      title: 'Flavor Profile',
      dataIndex: ['flavorProfile', 'name'],
      key: 'flavorProfile.name',
    },
    {
      title: 'Vintage',
      dataIndex: ['vintage'],
      key: 'vintage',
    },
    {
      title: 'UPC Code',
      dataIndex: ['upcCode'],
      key: 'upcCode',
    },
    {
      title: 'Source',
      dataIndex: ['type'],
      key: 'type',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      fixed: 'right',
      render: (text: any, record: Wine) => {
        let showActions = false;

        // admins can edit and delete everything
        if (currentUser?.isAdmin) {
          showActions = true;
        }

        // masters can edit and delete CompanyWine and StoreWine
        if (currentUser?.isMaster) {
          if (record.type === 'CompanyWine' || record.type === 'StoreWine') {
            showActions = true;
          }
        }

        if (currentUser?.isStore) {
          if (record.type === 'StoreWine') {
            showActions = true;
          }
        }

        if (!showActions) {
          return null;
        }
        return (
          <Dropdown
            placement={'bottomRight'}
            menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        let data: AddWineModalState = {
                          id: record.id,
                          upcCode: record.upcCode ?? '',
                          name: record.name ?? '',
                          description: record.description ?? '',
                          taste: record.taste ?? '',
                          producer: record.producer?.id ?? -1,
                          varietal: record.varietal?.id ?? -1,
                          flavorProfile: record.flavorProfile?.id ?? -1,
                          location: record.location?.id ?? -1,
                          foodPairings: record.foodPairings? record?.foodPairings: [],
                          thumbnailUrl: record.thumbnailUrl ?? '',
                          videoUrl: record.videoUrl ?? '',
                          onComplete: updateWines,
                          vintage: record.vintage ?? '',
                        };

                        addWineModalState.set(data);
                      }}
                    >
                      <FaPencilAlt /> Edit
                    </a>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <a
                      href="#"
                      className={'text-danger'}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log('delete', record);
                        if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                          setIsLoading(true);
                          deleteWine(record.id).then(() => {
                            updateWines();
                          });
                        }
                      }}
                    >
                      <FaTrash /> Delete
                    </a>
                  ),
                },
              ],
            }}
          >
            <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
              Action <FaCaretDown />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Card
        title={currentUser?.isStore ? 'Store Wines' : 'Company Wines'}
        extra={
          <>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openNewWineModal(updateWines);
              }}
            >
              Create Wine
            </a>
          </>
        }
      >
        <Table
          dataSource={wines}
          columns={unusedWineColumns}
          loading={isLoading}
          scroll={{ x: 1300 }}
          rowKey="id"
        />
      </Card>
    </>
  );
};
