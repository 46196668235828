import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

const listStores = async (): Promise<any> => {
  // const res = await axiosSecure.post(`${getContextRoot()}/store?max=25&offset=0`);
  const res = await axiosSecure.get(`${getContextRoot()}/store`);
  if (res.data?.stores) {
    return res.data.stores;
  }
  return res.data;
};

export default listStores;
