import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './style.scss';
import Widget1 from './widgets/Widget1';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use

if (window.location.pathname.includes('/widget1')) {
  root.render(<Widget1 />);
} else {
  root.render(<App />);
}
