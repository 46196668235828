import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Table } from 'antd';
import Store from '@interfaces/Store';
import { ColumnsType } from 'antd/lib/table';
import User from '@interfaces/User';
import AddUserModal, {
  AddUserModalState,
  addUserModalState,
  openNewUserModal,
} from './AddUserModal';
import listUsers from '@api/context/user/listUsers';
import currentUserState from '@globalState/currentUserState';
import { FaCaretDown, FaPencilAlt, FaTrash } from 'react-icons/fa';
import deleteUser from '@api/context/user/deleteUser';
import Heading from '@components/Heading';

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([] as User[]);
  const currentUser = currentUserState.useValue();

  const updateUsers = () => {
    setIsLoading(true);

    const getRole = (user: any) => {
      if (user.isAdmin) return 'Admin';
      if (user.isStore) return 'Store';
      if (user.isMaster) return 'Master';
    };
    listUsers().then((u) => {
      const cleanUsers = u.map((user: any) => {
        return {
          ...user,
          name: `${user.lastName}, ${user.firstName}`,
          role: getRole(user),
          storesString: user.stores.map((store: any) => store.name).join('|'),
        };
      });
      setUsers(cleanUsers);
      setUsers(cleanUsers);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    updateUsers();
  }, []);

  const userColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend'],
      sorter: (a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    currentUser?.isAdmin
      ? {
          title: 'Company',
          dataIndex: ['company', 'name'],
          key: 'company.name',
        }
      : null,
    {
      title: 'Store',
      dataIndex: ['storesString'],
      key: 'storeString',
      render: (text: string) => {
        console.log('text', text);
        return text.split('|').map((store: any, index: number) => <div key={index}>{store}</div>);
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      render: (text: any, record: any) => (
        <Dropdown
          placement={'bottomRight'}
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      const currentUser = currentUserState.get();
                      let data: AddUserModalState = {
                        id: record.id,
                        firstName: record.firstName,
                        lastName: record.lastName,
                        username: record.username,
                        email: record.email,
                        stores: record.stores.map((store: Store) => store.id),
                        role: (() => {
                          if (record.isAdmin) return 'ADMIN';
                          if (record.isMaster) return 'MASTER';
                          if (record.isStore) return 'STORE';
                          return 'STORE';
                        })(),
                        company: record.company?.id ?? -1,
                        onComplete: () => {
                          updateUsers();
                        },
                      };
                      if (currentUser?.isStore || currentUser?.isMaster) {
                        data.company = currentUser?.company?.id || -1;
                      }

                      addUserModalState.set(data);
                    }}
                  >
                    <FaPencilAlt /> Edit
                  </a>
                ),
              },
              {
                key: '2',
                label: (
                  <a
                    href="#"
                    className={'text-danger'}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('delete', record);
                      if (window.confirm(`Are you sure you want to delete "${record.name}"?`)) {
                        setIsLoading(true);
                        deleteUser(record.id).then(() => {
                          updateUsers();
                        });
                      }
                    }}
                  >
                    <FaTrash /> Delete
                  </a>
                ),
              },
            ],
          }}
        >
          <a className="ant-dropdown-link" href="#" onClick={(event) => event.preventDefault()}>
            Action <FaCaretDown />
          </a>
        </Dropdown>
      ),
    },
  ].filter((x) => x !== null) as ColumnsType<User>;

  return (
    <div style={{ padding: 20 }}>
      <Heading>Manage Users</Heading>
      <Card
        title="Users"
        extra={
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openNewUserModal(() => {
                updateUsers();
              });
            }}
          >
            Add User
          </a>
        }
      >
        <Table dataSource={users} columns={userColumns} loading={isLoading} rowKey="id"/>
      </Card>
      <AddUserModal />
    </div>
  );
};
