import axiosSecure from '@api/axiosSecure';
import getContextRoot from '@api/getContextRoot';

export interface Opt {
  username: string;
  password?: string;
  firstName: string;
  lastName: string;
  email: string;
  stores?: { id: number }[];
  isMaster: boolean;
  isStore: boolean;
  company: { id: number };
}

export interface AdminOpt extends Opt {
  isAdmin: boolean;
}

const addUser = async (opt: Opt | AdminOpt): Promise<any> => {
  const res = await axiosSecure.post(`${getContextRoot()}/user`, opt);
  return res.data;
};

export default addUser;
