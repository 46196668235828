import addVarietal from "@api/context/wine/varietal/addVarietal";
import modifyVarietal, { Opt } from "@api/context/wine/varietal/modifyVarietal";
import { alertApiErrors } from "@utils/axiosErrors";
import useFromNull from "@utils/useFromNull";
import { Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useRef } from "react";
import { useState } from "react";
import { createGlobalState } from "react-global-hooks";

export type AddVarietalModalState = {
    id?: number;
    name: string;
    type: string;
    wines: {
        id: number;
        name: string | null;
    } | null;
    onComplete?: () => void | Promise<void>;
};

const emptyAddUserModal: AddVarietalModalState = {
    name: '',
    type: "",
    wines: null
};

export const addVarietalModalState = createGlobalState(null as AddVarietalModalState | null);

export const openNewVarietalModal = (onComplete: () => void | Promise<void> = () => { }) => {
    addVarietalModalState.set({ ...emptyAddUserModal, onComplete: onComplete });
  };

const TextInput = React.forwardRef(
    (
      {
        name,
        label,
        handleChange,
        type = 'input',
      }: {
        name: string;
        label: string;
        handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
        type?: 'input' | 'textarea';
      },
      ref
    ) => {
      const addVarietalModal = addVarietalModalState.useValue();
      return (
        <div style={{ marginTop: 20 }}>
          {label}
          {type === 'textarea' ? (
            <TextArea
              name={name}
              placeholder={label}
              //@ts-ignore
              value={addVarietalModal?.[name] ?? ''}
              onChange={handleChange}
              //@ts-ignore
              ref={ref}
            />
          ) : (
            <Input
              name={name}
              placeholder={label}
              //@ts-ignore
              value={addVarietalModal?.[name] ?? ''}
              onChange={handleChange}
              //@ts-ignore
              ref={ref}
            />
          )}
        </div>
      );
    }
  );


const AddVarietalModal = () => {

    const [addVarietalModal, setAddVarietalModal] = addVarietalModalState.use();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isNew = !addVarietalModal?.id;
    const firstInputRef = useRef<any>(null);

    useFromNull(() => {
      setTimeout(() => {
        if (firstInputRef.current) {
          firstInputRef.current.focus();
        }
      }, 300);
      setIsLoading(true);
    }, [addVarietalModal]);
    
    const handleCancel = () => {
      setAddVarietalModal(null);
    };
  
    const handleSubmit = async () => {
        if (!addVarietalModal) return;
        setConfirmLoading(true);
        try {
            let opts: Opt = {
                name: addVarietalModal.name ?? '',
                wines:
                    addVarietalModal.wines ? { id: addVarietalModal.wines.id } : undefined,
            };
            if (isNew) {
                // todo add company for admins
                await addVarietal(opts);
            } else {
                // todo add company for admins
                await modifyVarietal(addVarietalModal.id as number, opts);
            }
            if (addVarietalModal?.onComplete) {
                await addVarietalModal.onComplete();
            }
            setConfirmLoading(false);
            setAddVarietalModal(null);
        } catch (e) {
            setConfirmLoading(false);
            alertApiErrors(e);
        }
    };

    const handleChange = (e: any) => {
        if (!addVarietalModal) return;
        setAddVarietalModal({
          ...addVarietalModal,
          [e.target.name]: e.target.value,
        });
      };
    

    return (
        <Modal
            title={`${isNew ? 'Add' : 'Edit'} Varietal `}
            open={!!addVarietalModal}
            confirmLoading={confirmLoading}
            onOk={handleSubmit}
            okText={'Save'}
            onCancel={handleCancel}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}
            >
                <div style={{ opacity: isLoading ? 0.5 : 1 }}>
                    <TextInput
                        name={'name'} label={'Varietal Name'} handleChange={handleChange} />
                </div>
            </form>
        </Modal>
    );
}


export default AddVarietalModal;

