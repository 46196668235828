import axiosSecure from '@api/axiosSecure';
import apiRoot from '@api/apiRoot';
import Notification from '@interfaces/Notification';

type Data = {
  title: string;
  content: string;
};
const addNotification = async (data: Data): Promise<Notification> => {
  const res = await axiosSecure.post(`${apiRoot}/admin/notification`, data);
  return res.data;
};

export default addNotification;
