import React from 'react';
import { message } from 'antd';
import { messageState } from '@utils/axiosErrors';

const MessageManager = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [messageObj, setMessageObj] = messageState.use();

  React.useEffect(() => {
    // for loop removing first item from messageObj until there are no more items
    if (messageObj.length > 0) {
      const m = [...messageObj];
      while (m.length > 0) {
        const messageItem = m.shift();
        if (messageItem) {
          messageApi.open({
            type: messageItem.type,
            content: messageItem.content,
          });
        }
      }
      setMessageObj([]);
    }
  }, [messageObj]);

  return <>{contextHolder}</>;
};

export default MessageManager;
