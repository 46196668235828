import { getAxiosErrors } from '@utils/axiosErrors';
import axios, { AxiosError } from 'axios';
import authState, { AuthState } from '@globalState/authState';
import apiRoot from '@api/apiRoot';

const loginUser = async (username: string, password: string) => {
  try {
    const response = await axios.post(`${apiRoot}/login`, {
      username: username,
      password: password,
    });
    authState.set(response.data as AuthState);
    return null;
  } catch (error: AxiosError | unknown) {
    // @ts-ignore
    if (error?.response?.status === 401) {
      return ['Invalid username or password'];
    }
    return getAxiosErrors(error);
  }
};

export default loginUser;
